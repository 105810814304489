import { Component, Inject, OnInit, Optional } from '@angular/core';
//import { AppService } from 'projects/gtw-elements/src/app/app.service';
//import { AdminFactoryService } from 'projects/gtw-elements/src/app/global/services/admin-factory.service';
//import { GlobalService } from 'projects/gtw-elements/src/app/global/services/global.service';
import { GtwSnackbarService } from 'projects/gtw-elements/src/app/shared/_services/gtw-snackbar.service';
//import { TeamplateAssignService } from '../../template-assign/teamplate-assign.service';
//import { NotifactionService } from '../../notification/notifaction.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAccessApprovalService } from '../user-access-approval.service';
import _ from 'lodash';

@Component({
  selector: 'gtw-template-assign-request-approval',
  templateUrl: './template-assign-request-approval.component.html',
  styleUrls: ['./template-assign-request-approval.component.scss']
})
export class TemplateAssignRequestApprovalComponent implements OnInit {
  user: any = {};
  clients: any = [];
  selectedAppClient: any = {};
  userList: any = [];
  templateGroups: any = {};
  allTemplates: any = [];
  templatesGroup: any = [];
  minimise: boolean = false;
  title: string = '';
  taskModel: string = '';
  searchText: string = '';
  selectedTemplate: any;
  rowData : any = {};

  constructor(
    private alertService: GtwSnackbarService,
    public diagRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private userAccessApprovalService: UserAccessApprovalService
  ) {}

  userSettings: any; // = USER_SETTINGS;

  applicationFilter: any = {
    values: [],
    selected: [],
    name: 'Application(s)',
    model: null,
  };
  showModuleLoader: boolean = false;
  showTemplateLoader: boolean = false;
  selectedTemplates: any = {};
  user_id: any; // = this.userSettings.user.sso_id;
  actionType: any = 'TemplateAssignFactory';
  multipleAssignParam: any = {
    templateId: [],
    userId: [],
  };
  showOverlayScreen: boolean = false;
  showModuleOverlay: boolean = true;
  global_params: any; // = angular.copy(GlobalService.globalParams);
  saveDisabled: boolean = true;

  loading: boolean = false;
  channelList: any;
  selectedChannel: any;

  private eventsSubscription!: Subscription;

  ngOnInit(): void {
    this.rowData = this.data;
    this.title = "Assign Template";
    if(this.data.templatesData){
      this.selectedTemplates = this.data.templatesData;
    }
    
    this.userSettings = this.data.globalService.userSettingsData;
    this.user = this.userSettings.user;
    this.clients = this.user.clients;
    this.selectedAppClient = _.find(this.clients, {
      client_key: this.user.client.client_key,
    });
    this.global_params = this.data.globalService.globalParams;
    this.userList = [];
    this.getAllTemplates(this.user.client.client_key);
  }
  getAllTemplates(clientKey: any) {
    this.showTemplateLoader = true;
    this.loading = true;
    this.userAccessApprovalService
      .getAppKeys(clientKey, this.data.baseURLs.api)
      .toPromise()
      .then((data: any) => {
        this.channelList = data.channel.p_cursor;
        this.selectedChannel = this.channelList[0];
        this.userAccessApprovalService
          .getConfigSettings(this.selectedChannel.channel_key, this.data.baseURLs.api)
          .toPromise()
          .then((data: any) => {
            //this.templateGroups = JSON.parse(data.configSettings[142].VALUE);
            let tempGroupObj: any = _.find(data.configSettings, {
              NAME: 'access_template_groups',
            });
            this.templateGroups = JSON.parse(tempGroupObj.VALUE);
            this.loading = false;

            this.userAccessApprovalService
              .getAllTemplates(this.selectedAppClient.client_key, this.data.baseURLs.api).toPromise()
              .then((data: any) => {
                this.showTemplateLoader = false;
                if (data.callSuccess === '1') {
                  console.log(data.jsonObject);
                  this.allTemplates = data.jsonObject;
                  this.templatesGroup = _.groupBy(
                    this.allTemplates,
                    'TEMPLATE_GROUP_KEY'
                  );
                } else {
                  if (data.errorMessage === 'no access') {
                    this.alertService.openSnackBar('Sorry you do not have access to retrieve Cache control details.', 'gtw-snackbar--red');
                  } else {
                    this.alertService.openSnackBar('Unable to retrieve. If you continue to experience this issue please visit the help page to contact support.', 'gtw-snackbar--red');
                  }
                }
              });
          });
      });
  }

  isLoadingEmail_to: boolean = false;
  filteredOptions: any;
  emailSelected = new FormControl();

  showModulesListing: boolean = false;

  totalModulesForSelectedClient: any;
  getGroupName(value: any) {
    //   console.log(this.templateGroups);
    return Object.keys(this.templateGroups).find(
      (key) => this.templateGroups[key] == value
    );
  }

  saveTemplates(){
    console.log(this.selectedTemplates);
    this.rowData.templatesData = this.selectedTemplates;
    this.userAccessApprovalService.setTemplateAssignData(this.rowData);
    this.cancel();
  }

  cancel(){
    this.diagRef.close();
  };

}
