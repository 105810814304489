<div id="overlay">
  <ap-loading *ngIf="startLoader" id="text" class="loader-class" [colorName]="'a-primary'">
  </ap-loading>
</div>
<dst-create-disclosure-actions [isView]="true" [disableAll]="disableActions" [disabled]="!loaded" (onSaveClick)="save()"
  (onCancelClick)="cancel()" [disableS3Upload]="false" (onS3UploadClick)="s3Upload()"
  [isFiled]="stmtData?.isfiled" [isQAR]="stmtData?.isqar"
   [disabledS3]="s3Uploading" [statementId]="stmtData?.statementid"></dst-create-disclosure-actions>
<div class="form-template-wrapper" id="temp367bform192">
  <div align="center">
    <b>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.dcnname}}
      </span>
    </b>
  </div>
  <div align="center">
    <B>
      <span class="template-color-blue" *ngIf="loaded">
        {{stmtData.taxyear}} CONSOLIDATED FEDERAL INCOME TAX RETURN<br />
        STATEMENT REQUIRED PURSUANT TO SECTION 1.367(b)-1(c)(4) OF THE REGULATIONS
      </span>
    </B>
  </div>
  <br />
  <div [formGroup]="form192" class="section-content ap-form-field-my-wrapper">
    Parties:
    <ol>
      <li>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryentity == null? '':
          stmtData.secondaryentity}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryein == null? 'N/A':
          stmtData.secondaryein}}</span><span>, Transferor, a</span>
        <span *ngIf="secEntityNull">Transferee </span><span class="template-color-blue" *ngIf="loaded">
          {{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}} {{secondaryCorpTypeTxt}}</span><span>; and</span>
      </li>
      <li>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryentity == null? '':
          stmtData.primaryentity}}</span><span>, EIN:&nbsp;</span>
        <span class="template-color-blue" *ngIf="loaded">{{stmtData.primaryein == null? 'N/A':
          stmtData.primaryein}}</span><span>, Transferee, a</span>
          <span class="template-color-blue" *ngIf="loaded">
            <span *ngIf="prEntityNull">Transferor </span>
            {{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} </span><span> Corporation</span><span>.</span>
        <!-- <span class="template-color-blue" *ngIf="loaded">
          <span *ngIf="prEntityNull">Transferor </span>
          {{stmtData.primarycountryincdesc == null? '': stmtData.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span><span>.</span> -->
        <ol class="inner-list">
          <li>
            The exchange is one to which Section 367(b) of the Internal Revenue Code of 1986, as amended, (the
            &quot;Code&quot;) applies.
          </li>
          <li>

            On <span *ngIf="loaded" class="template-color-blue">{{attMap['DisplayDate'] == undefined ? '' :
              attMap['DisplayDate'][0]}}</span>,
            &nbsp;<span class="template-color-blue" *ngIf="loaded">{{stmtData.secondaryentity == null? '':
              stmtData.secondaryentity}}</span>, a <span class="template-color-blue" *ngIf="loaded">
              {{stmtData.secondarycountryincdesc == null? '': stmtData.secondarycountryincdesc}} </span> <span
              class="corpTypeSpan statement-highlight template-color-blue">{{ secondaryCorpTypeTxt }}</span>, (the "Significant Transferor"),
            transferred the net
            assets listed below to <span class="statement-highlight template-color-blue" *ngIf="loaded">{{stmtData.primaryentity == null? '': stmtData.primaryentity}}</span>,
            a <span class="statement-highlight template-color-blue" *ngIf="loaded">{{stmtData.primarycountryincdesc == null ? '': stmtData.primarycountryincdesc}} {{primaryCorpTypeTxt}}</span>,
            (the
            "Transferee")
            in an exchange pursuant to Sec. 351 of the Code.


            <div class="additional-text" *ngIf="loaded">
              <span>Additional Information:</span><br />
              <span>{{attMap['section4text'] == undefined ? '' : attMap['section4text'][0]}}</span>
            </div>
            <br />
            <table class="table-view-layout">
              <tbody *ngIf="loaded">
                <tr>
                  <td colspan="3"></td>
                  <td style="width: 20%; text-align: center;"><span>{{selBasisTxt}} Basis to the Transferor</span></td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td class="header-column con-left"><span><b>Description</b></span></td>
                  <td>
                    <span><u>{{getSelCurrency(0)}}</u></span>
                  </td>
                </tr>
                <ng-container ng-if="attMap['Desc1'].length >= 1">

                  <tr *ngFor="let val of attMap['Desc1'];let i=index">
                    <td colspan="2"></td>
                    <td class="con-left"><span>{{val}} </span></td>
                    <td>
                      <span>{{attMap['BasisAmt1'] == undefined ? '' : attMap['BasisAmt1'][i] | number}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="basisAmt1Total">
                    <td colspan="2"></td>
                    <td></td>
                    <td>
<!--                      <hr class="dstAmtHr" />-->
                    </td>
                  </tr>
                  <tr *ngIf="basisAmt1Total">
                    <td colspan="2"></td>
                    <td class="con-left"><span>Total</span></td>
                    <td style="border-top: 1px solid;">
                      <span>{{totalAmt('BasisAmt1') | number}}</span>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
          </li>
          <li>
            <div *ngIf="loaded">
              {{ attMap['Shares'] === undefined ? '' : attMap['Shares'][0]
                }}&nbsp;shares of the Transferee were issued to the Transferor in the exchange. The Transferor
              owned
              &nbsp;{{ attMap['PercentageX'] === undefined ? '' :
                attMap['PercentageX'][0] }}&nbsp;% and&nbsp;
              {{ attMap['PercentageY'] === undefined ? '' : attMap['PercentageY'][0]
                }}&nbsp;% of the {{stockTypeName}} shares of the Transferee immediately before and after the exchange,
              respectively.
            </div>
          </li>
          <li>
            None.
          </li>
          <li>
            For information required pursuant to regulations under Code Section 351, see the separate statement.
          </li>
          <li>
            Any information required to be furnished with respect to the exchange under sections 6038, 6038B, or 6046,
            or the regulations under those sections has been provided with this tax return on Form 5471, Information
            Return of U.S. Persons with Respect to Certain Foreign Corporations.
          </li>
          <li>
            Not applicable.
          </li>
        </ol>
      </li>
    </ol>

    <table style="width:100%">
      <tbody *ngIf="loaded">
        <tr>
          <td class="footer-left">
            LEIDs: <span *ngIf="prEntityNull">TransferorLEID </span> <span
              class="template-color-blue">{{stmtData.primaryleid == null? '': stmtData.primaryleid}}</span> / <span
              *ngIf="secEntityNull">TransfereeLEID </span> <span class="template-color-blue">{{stmtData.secondaryleid ==
              null? '': stmtData.secondaryleid}}</span>
          </td>
          <td class="footer-right" *ngIf="stmtData.branchleid">
            Branch LEID: <span class="template-color-blue">{{stmtData.branchleid == null? '':
              stmtData.branchleid}}</span>
          </td>
        </tr>
        <tr>
          <td class="footer-left">
            Statement ID: <span class="template-color-blue"> {{stmtData.statementid}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
