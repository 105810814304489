import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//import { StateService } from '@uirouter/core';
//import { AppService } from 'projects/gtw-elements/src/app/app.service';
import { GtwSnackbarService } from 'projects/gtw-elements/src/app/shared/_services/gtw-snackbar.service';
import { UserAdminService } from '../user-admin.service';
//import { GlobalService } from 'projects/gtw-elements/src/app/global/services/global.service';
import { Dictionary, NumericDictionary } from 'lodash';
import _ from 'lodash';
//import { JsonObjectFactoryService } from 'projects/gtw-elements/src/app/global/services/json-object-factory.service';
//import { environment } from 'projects/gtw-elements/src/environments/environment';
//import { setFormData } from '../../../shared/admin.utilities';
//import { UserAccessApprovalService } from '../../user-access-approval/user-access-approval.service';
import { GridEventService } from '../../shared/_services/grid-event.service';
import { UtilityService } from '../../shared/_services/utility.service';
import { UserAccessApprovalService } from '../../admin/components/user-access-approval/user-access-approval.service';
import { JsonObjectFactoryService } from '../../shared/_services/json-object-factory.service';
import { GtwElementsBaseUrlsModel } from '../../shared/_models/gtw-elements-base-urls.model';

@Component({
  selector: 'gtw-user-business',
  templateUrl: './user-business.component.html',
  styleUrls: ['./user-business.component.scss']
})
export class UserBusinessComponent implements OnInit, AfterViewInit {


  @Input() rowData: any;
  @Input() requestApprovalScreen: any;
  @Input() index: any;
  @Output() emitEvent = new EventEmitter<string>();
  user_id: any;
  bizzHierarchies: any;
  defaultGroupObjKey!: number;
  businessAssignNav: any;
  enitiyAssignNav: any;
  loading!: boolean;
  busHierarchyLoading!: boolean;
  busHierarchiesLoading!: boolean;
  busLoading!: boolean;
  entityLoading!: boolean;
  userSettings: any;
  logged_in_user: any;
  legalEntities: any;
  selectedLE!: string;
  txYearScenarioJcdData: any;
  txYearScenarioJcdOptions: any;
  txyear_scenario_jcd!: string;
  taxYear: any;
  scenario: any;
  grpObjKey!: any;
  reportingMonth!: number;
  renderingMode!: number;
  taxYears: any;
  clients: any;
  client_key: any;
  bizzHierGrpObjDetails: any;
  bizzHierGrpObjDetailsMap: any;
  selectedGrpObjKeys: any;
  selectedUserClientsList: any;
  selected_scenario: any;
  isPreviewAvailable!: boolean;
  previewMode!: number;
  grpObjKeys: any;
  crudLoading!: boolean;
  jcdKey: string = '';
  globalService: any;
  GENERAL_CONFIG: any;
  workspaceFactory: any;
  baseURLs: any;

	@Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls = baseUrls;
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

  @Input('header-data')
	set headerData(headerData : any){
		try{
			this.globalService = headerData;
      //this.workspaceFactory = this.globalService.workspaceFactory;
			// this.appService.USER_SETTINGS = this.globalService.userSettingsData;
			// this.appService.SERVER_CONFIG = this.globalService.serverConfigData;
			this.GENERAL_CONFIG = this.globalService.generalConfigData;
			this.init();

		}catch(e){			
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

  constructor(
    //private stateService: StateService,
    //private appService: AppService,
    private UserAdminFactory: UserAdminService,
    private AlertService: GtwSnackbarService,
    private gridEventService: GridEventService,
    //private globalService: GlobalService,
    private JsonObjectFactory: JsonObjectFactoryService,
    private userAccessApprovalService: UserAccessApprovalService,
    private utilityService: UtilityService,
  ) { }

  ngAfterViewInit(): void {
    this.gridEventService.on('selectedUserClientsList','client',(event: any,args: any) =>{
      this.selectedUserClientsList = args;
      console.log("vm.selectedUserClientsList ::",this.selectedUserClientsList);
    });
  }

  ngOnInit(): void {};

  init(): void {
    this.user_id = this.rowData.SSO_ID;
    this.bizzHierarchies = []; //Stored All Selected Bizz Hierarchies
    this.defaultGroupObjKey = 0;
    this.businessAssignNav = [];
    this.enitiyAssignNav = [];
    this.loading = false;
    this.busHierarchyLoading = false;
    this.busHierarchiesLoading = false;
    this.busLoading = false;
    this.entityLoading = false;
    this.userSettings = this.globalService.userSettingsData;
    this.logged_in_user = this.userSettings.user.sso_id;
    this.legalEntities = [];
    this.selectedLE = '';
    this.txYearScenarioJcdData = [];
    this.txYearScenarioJcdOptions = [];
    let isDataAvailable = false;
    this.txyear_scenario_jcd = "";
    this.taxYear = '2020'; //this.globalService.globalParams.tax_year;
    this.scenario = '40'; //this.globalService.globalParams.scenario;
    this.grpObjKey = 0;
    this.reportingMonth = 0;
    this.renderingMode = 0;

    this.taxYears = [];
    let user = this.userSettings.user;
    this.clients = user.clients;
    console.log(this.clients, "which one");
    this.client_key = this.userSettings.user.client_key; // this.clients[0].client_key;
    console.log("user ::", user);
    /*console.log("SERVER_CONFIG default jcd key::",SERVER_CONFIG.configSettings.default_jcd_key);
    console.log("SERVER_CONFIG default scenario::",SERVER_CONFIG.configSettings.default_scenario);*/
    console.log("SERVER_CONFIG default tax year::", this.globalService.serverConfigData.configSettings.default_tax_year);
    console.log("GlobalService.globalParams ::", this.globalService.globalParams);

    this.bizzHierGrpObjDetails = [];
    this.bizzHierGrpObjDetailsMap = []; //Group Obj Key as Key Item, Group Obj Details as Key Value
    this.selectedGrpObjKeys = [];
    this.selectedUserClientsList = [];
    this.getTaxYearsByClient(this.client_key); //Fills Tax Years Drop Down
    this.getGroupObjKeyByClientTaxYrJcdScenario(); //get default group object key
    this.getObjectKeysByClientAndTaxYear(); //Retrieving All Grp Object Keys for Given Client Key and Tax Year
    this.getBizzHierGroupObjDetails(); //Retrieving all distinct GRP OBJ KEYS for given client across all years
  }


  getName($index: any, anObj: any) {
    return `${$index + 1}. Business Hierarchy - ${anObj.TAX_YEAR}`
  }
  cancelBus() {
    this.getData();
  }

  updateSelectedGroupObjKeys(grpObjKey: any, selected_tax_year?: any, selected_scenario?: any) {
    console.log("grpObjKey ::", grpObjKey);
    console.log("this.selectedGrpObjKeys ::", this.selectedGrpObjKeys);
    console.log("this.bizzHierarchies ::", this.bizzHierarchies);
    console.log("bizzHierGrpObjDetails ::", this.bizzHierGrpObjDetails);
    console.log({ selected_tax_year, selected_scenario });
    this.selected_scenario = selected_scenario;
    this.selectedGrpObjKeys = [];
    this.bizzHierarchies = [];
    let tempGrpObjKey = _.indexOf(this.selectedGrpObjKeys, grpObjKey);
    console.log("tempGrpObjKey ::", tempGrpObjKey);
    console.log("this.selectedGrpObjKeys ::", this.selectedGrpObjKeys);
    if (tempGrpObjKey < 0) {
      this.selectedGrpObjKeys = []; //empty it as we are not simultaneously displaying multiple bizz hierarchies
      this.selectedGrpObjKeys.push(grpObjKey); //place in selected grp objects array

      let selBizHier = _.find(this.bizzHierarchies, "group_obj_key");
      //found in the cache
      if (selBizHier && selBizHier.group_obj_key === grpObjKey) {
        _.forEach(this.bizzHierarchies, function (item: { group_obj_key: any; visible: boolean; }, index: any) {
          if (item.group_obj_key === grpObjKey) {
            item.visible = true;
            console.log("Marking Business Hierarchy as Visible GrpObjKey ::", item.group_obj_key);
          } else {
            item.visible = false;
            console.log("Marking Business Hierarchy as Invisible GrpObjKey ::", item.group_obj_key);
          }
        });
      } else {
        //not found in cache, pull from db
        this.getBizzHierarchyByGroupObjKey(grpObjKey);
      }
      //check box - checked/not checked logic
      _.forEach(this.bizzHierGrpObjDetails, function (item: { GROUP_OBJ_KEY: any; selected: boolean; open: boolean; group_obj_key: any; }, index: any) {
        if (item.GROUP_OBJ_KEY === grpObjKey) {
          item.selected = true;
          item.open = true;
          console.log("Marking JCD Table Checkbox checked GrpObjKey ::", item.group_obj_key);
        } else {
          item.selected = false;
          console.log("Marking JCD Table Checkbox checked GrpObjKey ::", item.group_obj_key);
        }
      });
    } else {
      _.pull(this.selectedGrpObjKeys, grpObjKey);
      _.forEach(this.bizzHierarchies, function (item: { group_obj_key: any; visible: boolean; }, index: any) {
        //marking invisible in bizzheries cache
        if (item.group_obj_key === grpObjKey) {
          item.visible = false;
          console.log("Marking Business Hierarchy as Invisible GrpObjKey ::", item.group_obj_key);
        }
      });
      //check box - checked/not checked logic
      _.forEach(this.bizzHierGrpObjDetails, function (item: { GROUP_OBJ_KEY: any; selected: boolean; group_obj_key: any; }, index: any) {
        if (item.GROUP_OBJ_KEY === grpObjKey) {
          item.selected = false;
          console.log("Marking JCD Table Checkbox checked GrpObjKey ::", item.group_obj_key);
        }
      });
    }
    console.log("selectedGrpObjKeys ::", this.selectedGrpObjKeys);
  }

  /**Retrieving Business Hierarchy for given Group Object Key and for given SSO**/
  getBizzHierarchyByGroupObjKey(argGroupObjKey: any) {
    this.busHierarchyLoading = true;
    let data: any = {};
    data.taxyear = this.taxYear;
    data.sso_id = this.logged_in_user;
    data.userSsoId = this.user_id;
    data.grp_obj_key = argGroupObjKey;
    data.isBusHierAssign = 'Y';
    data.clientKey = this.client_key;
    console.log("Data ::", data);
    this.previewMode = 0;

    if (this.taxYear && argGroupObjKey) {
      this.UserAdminFactory.getBizzHierarchyBySsoByGroupObjKey(this.baseURLs, this.utilityService.convertToFormData(data)).subscribe( (data: { callSuccess: string; jsonObject: string | any[]; errorMessage: string; }) => {
        this.busHierarchyLoading = false;
        if (data.callSuccess === "1") {
          if (data.jsonObject.length > 0) {

            //make all biz hierarchies invisible
            _.forEach(this.bizzHierarchies, function (item: { visible: boolean; }, index: any) {
              item.visible = false;
            });
            this.businessAssignNav = data.jsonObject;
            let record = { group_obj_key: argGroupObjKey, value: this.businessAssignNav, visible: true };
            this.bizzHierarchies.push(record);
            console.log("data.jsonObject ", data.jsonObject);
            console.log("bizzHierarchies ", this.bizzHierarchies);
            this.isPreviewAvailable = true;
          } else {
            //make all biz hierarchies invisible
            _.forEach(this.bizzHierarchies, function (item: { visible: boolean; }, index: any) {
              item.visible = false;
            });
            this.AlertService.openSnackBar("No businesses assigned", 'gtw-snackbar--red');
            //+" Group Obj Key: "+argGroupObjKey
          }
        } else {
          if (data.errorMessage === "no access") {
            this.AlertService.openSnackBar("Sorry you do not have access to do the requested action.", 'gtw-snackbar--red');
          } else {
            this.AlertService.openSnackBar("An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
          }
        }
      });
    } else {
      this.AlertService.openSnackBar("Please make sure Tax Year, Client and Tax Years are selected", 'gtw-snackbar--red');
      this.loading = false;
    }
  }

/** Retrieving All Tax Years Based on Selected Client Key - Populates TAX YEARS Drop Down**/
getTaxYearsByClient(clientKey: number) {
  console.log("Inside getTaxYearsByClient()", clientKey);
  /** Resetting Staged Bizz Hierarchy Data**/

  if (clientKey > 0) {
    this.loading = true;
    let params = { action_code: 'etxzx9', sso_id: this.logged_in_user, client_key: clientKey };

    this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; jsonObject: Dictionary<unknown> | NumericDictionary<unknown> | null | undefined; errorMessage: string; }) => {
      this.loading = false;
      console.log("getTaxYearsByClient() returned Data ::", data);
      if (data.callSuccess === "1") {
        if (data.jsonObject) {
          this.taxYears = _.map(data.jsonObject, 'TAX_YEAR');
        }
        console.log("this.taxYears ::", this.taxYears);

      } else {
        if (data.errorMessage === "no access") {
          this.AlertService.openSnackBar("Sorry you do not have access to retrive Tax Years.", 'gtw-snackbar--red');
        } else {
          this.AlertService.openSnackBar("Unalbe to retrieve Tax Years. If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
        }
      }

    });

  }
}

resetData(argClientKey: any) {
  this.selectedGrpObjKeys = [];
  this.bizzHierarchies = [];
  this.bizzHierGrpObjDetails = [];
  this.defaultGroupObjKey = 0;
  this.getGroupObjKeyByClientTaxYrJcdScenario(); //get default group object key
  this.getObjectKeysByClientAndTaxYear(); //Retrieving All Grp Object Keys for Given Client Key and Tax Year
  this.getBizzHierGroupObjDetails(); //Retrieving all distinct GRP OBJ KEYS for given client across all years
}

/**Retrieving Group Object Details for all Bizz Hierarchies **/
getBizzHierGroupObjDetails() {
  if (this.client_key >= 0) {
    this.busHierarchiesLoading = true;
    let params = { action_code: 'eheat9', sso_id: this.logged_in_user, client_key: this.client_key };

    this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; jsonObject: any; errorMessage: string; }) => {
      this.busHierarchiesLoading = false;
      console.log("getBizzHierGroupObjDetails() returned Data ::", data);
      if (data.callSuccess === "1") {
        this.bizzHierGrpObjDetails = data.jsonObject;
        console.log("getBizzHierGroupObjDetails-->bizzHierGrpObjDetails ::", this.bizzHierGrpObjDetails);
        _.forEach(this.bizzHierGrpObjDetails,  (item: { GROUP_OBJ_KEY: number; selected: boolean; open: boolean; }, index: any) => {
          if (item.GROUP_OBJ_KEY === Number(this.defaultGroupObjKey)) {
            item.selected = true;
            item.open = true;
          } else {
            item.selected = false;
          }
        });
      } else {
        if (data.errorMessage === "no access") {
          this.AlertService.openSnackBar("Sorry you do not have access to retrive Tax Years.", 'gtw-snackbar--red');
        } else {
          this.AlertService.openSnackBar("Unalbe to retrieve Business Hierarchy Group Object Details. If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
        }
      }

    });
  } else {
    this.AlertService.openSnackBar("Please Select Client", 'gtw-snackbar--red');
  }
}


/** Retrieving All Group Obj Keys based on selected Tax Year and Client - To Render Group Obj Master Table on UI**/
getObjectKeysByClientAndTaxYear() {
  console.log("Get Group Obj Key By Tax Year and Client Fired");

  this.grpObjKey = [];
  if (this.taxYear && this.client_key) {
    this.loading = true;
    let params = { action_code: 'etbll9', sso_id: this.logged_in_user, client_key: this.client_key, taxyear: this.taxYear };

    this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; jsonObject: any; errorMessage: string; }) => {
      this.loading = false;
      if (data.callSuccess === "1") {
        this.grpObjKeys = data.jsonObject;
        this.txYearScenarioJcdOptions = data.jsonObject;
        console.log("this.txYearScenarioJcdOptions ::", this.txYearScenarioJcdOptions);


      } else {
        if (data.errorMessage === "no access") {
          this.AlertService.openSnackBar("Sorry you do not have access to do the requested action.", 'gtw-snackbar--red');
        } else {
          this.AlertService.openSnackBar("An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
        }
      }
      console.log("getObjectKeyByClientAndTaxYear() returned Data ::", data);
    });

  }
}

/** Retrieving Default Group Obj Key based on TGT_CONFIG Default Tax Year/JCD/Scenario**/
getGroupObjKeyByClientTaxYrJcdScenario() {
  /*if(this.defaultGroupObjKey>0){
    this.updateSelectedGroupObjKeys(Number(this.defaultGroupObjKey));
   }else{
    this.defaultGroupObjKey = SERVER_CONFIG.configSettings.default_bizz_hier_group_obj_key_ge;
   this.updateSelectedGroupObjKeys(Number(this.defaultGroupObjKey));
   }*/

  //Following is commented because Group Object Key will be coming from TGT_CONFIG table
  this.selectedGrpObjKeys = [];


  if (this.taxYear && this.client_key >= 0 && this.scenario) {


    this.loading = true;
    let params = { action_code: 'emall9', sso_id: this.logged_in_user, client_key: this.client_key, taxyear: this.taxYear, default_scenario: this.scenario };

    this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; jsonObject: string | any[]; errorMessage: string; }) => {
      this.loading = false;
      console.log("getGroupObjKeyByClientTaxYrJcdScenario data::", data);
      if (data.callSuccess === "1") {
        if (data.jsonObject && data.jsonObject.length > 0) {
          this.defaultGroupObjKey = data.jsonObject[0].GROUP_OBJ_KEY;
          if (this.defaultGroupObjKey > 0) {
            this.updateSelectedGroupObjKeys(this.defaultGroupObjKey);
          }
          console.log("getGroupObjKeyByClientTaxYrJcdScenario grpObjKeys::", this.defaultGroupObjKey);
        } else {
          this.AlertService.openSnackBar("No businesses assigned..", 'gtw-snackbar--red');
        }
      } else {
        if (data.errorMessage === "no access") {
          this.AlertService.openSnackBar("Sorry you do not have access to do the requested action.", 'gtw-snackbar--red');
        } else {
          this.AlertService.openSnackBar("An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
        }
      }
    });
  } else {
    this.AlertService.openSnackBar("Select: Tax Year Client Key and Scenario from Left Nav", 'gtw-snackbar--red');
  }
}

/** Removes all assigned business hierarchies for given SSO**/
removeAll() {
  console.log("Removes all assigned business hierarchies..");
  this.loading = true;
  let params = { action_code: 'ekkkk9', user_sso_id: this.user_id, compliance: 'GTW' };
  console.log("removeAll() params ::", this.utilityService.convertToFormData(params));
  this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; errorMessage: string; }) => {
    this.loading = false;
    console.log("removeAll data::", data);
    if (data.callSuccess === "1") {
      this.AlertService.openSnackBar("Removed All Business Hierarchies for SSO::" + this.user_id, 'gtw-snackbar--green');
      if (this.defaultGroupObjKey > 0) {
        this.getBizzHierarchyByGroupObjKey(this.defaultGroupObjKey);
      }
    } else {
      if (data.errorMessage === "no access") {
        this.AlertService.openSnackBar("Sorry you do not have access to do the requested action.", 'gtw-snackbar--red');
      } else {
        this.AlertService.openSnackBar("An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
      }
    }
  });

}

//TODO: SEND BACK BUSINESS(ES) TO DB CALL
saveBus(argGrpObjKey: any) {

  //Logic to find appropriate Bizz hierrachy based on Group Obj Key
  console.log("saveBus() argGrpObjKey ::", argGrpObjKey);
  // let selBizHier = _.find(this.bizzHierarchies, "group_obj_key");
  let selBizHier = _.find(this.bizzHierarchies, { group_obj_key: argGrpObjKey })
  console.log("saveBus() Request received to Save  ::", selBizHier);
  let toBeSavedBizzHier = [];
  let taxYr = "";
  if (selBizHier) {
    toBeSavedBizzHier = selBizHier.value;
    console.log("saveBus() Preparing to Save  ::", toBeSavedBizzHier);
  }

  //GET ALL CHECKED THAT DO NOT HAVE CHILDREN PROPERTIES;
  let selectedArray: {}[] = [];
  let isAll = "N";
  function getSelected(node: { selected: any; isleaf: number; mekey: any; grpobjkey: any; taxyear: string; children: any; }) {
    let record: any = {};
    if (node.selected && node.isleaf === 0) {
      isAll = "Y";
      record.ME_KEY = node.mekey;
      record.LE_KEY = '0';
      record.IS_ALL = isAll;
      record.GRP_OBJ_KEY = node.grpobjkey;
      record.TAX_YEAR = node.taxyear;
      taxYr = node.taxyear;
      selectedArray.push(record);

      _.forEach(node.children, function (value, key) {
        getSelected(value)
      });
    } else if (node.selected && node.isleaf === 1) {
      record.ME_KEY = node.mekey;
      record.LE_KEY = '0';
      record.IS_ALL = isAll;
      record.GRP_OBJ_KEY = node.grpobjkey;
      record.TAX_YEAR = node.taxyear;
      taxYr = node.taxyear;
      selectedArray.push(record);
    } else if (node.children) {
      _.forEach(node.children, function (value, key) {
        getSelected(value)
      });
    }
  }
  _.forEach(toBeSavedBizzHier, function (value, key) {
    getSelected(value)
  })
  console.log("saveBus() Bizz Hierarchy for Save :", selectedArray);
  /**Incase user clicked Unassign-All and clicked save, then derive Tax Year from (Left Side) JCD/Scenario Bizz Hierarchy Table **/
  if (selectedArray.length <= 0) {
    _.forEach(this.bizzHierGrpObjDetails, function (item: { selected: any; TAX_YEAR: string; }, index: any) {
      if (item.selected) {
        taxYr = item.TAX_YEAR;
        console.log("Selected Tax Year from Group Obj Details(LeftPanel) ::", taxYr);
      }
    });
  }
  let service = this.JsonObjectFactory;
  let url = this.GENERAL_CONFIG.base_url + "/saveJsonObject?action_code=etbst9";
  let jsonObj = JSON.stringify(selectedArray);
  let jsonSettings = '{}';
  let params: any = _.extend({ jsonObj: jsonObj }, {});
  params = _.extend({ jsonSettings: jsonSettings }, params);
  params.tax_year = taxYr; //GlobalService.globalParams.tax_year; //"2017";
  params.sso_id = this.logged_in_user;
  params.userSsoId = this.user_id;
  params.compliance = "GTW";
  params.grp_obj_key = argGrpObjKey;
  params.scenario = this.selected_scenario;


  console.log("params ::", params);

  if(this.requestApprovalScreen == true){
    console.log("This is only for Request Approval Screen");
    console.log(this.index);
    this.userAccessApprovalService.setBusinessAssignData(this.index, selectedArray);
  }else{
    service.saveJSON(url, params, this.globalService).subscribe( (data: { callSuccess: string; }) => {
      if (data.callSuccess === "1") {
        console.log(data);
        this.AlertService.openSnackBar("Business Hierarchy Saved Successfully.", 'gtw-snackbar--green');
        this.crudLoading = false;
      } else {
        this.AlertService.openSnackBar("An error has occurred. If this error continues please go to the help page and contact support", 'gtw-snackbar--red');
        this.crudLoading = false;
      }
    });
  }
}

getTxYearScenarioOptions() {
  this.loading = true;
  let params = { action_code: 'dkm185', sso_id: this.logged_in_user };

  this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { callSuccess: string; jsonObject: any; errorMessage: string; }) => {
    this.loading = false;
    if (data.callSuccess === "1") {
      this.txYearScenarioJcdData = data.jsonObject;
      _.forEach(data.jsonObject,  (item: { TAX_YEAR: string; SCENARIO: string; JCD_KEY: string; SCENARIO_DESC: string; JURISDICTION_CODE: string; DOMAIN_NAME: string; CHANNEL_NAME: string; }) => {
        let keyItem = item.TAX_YEAR + '-' + item.SCENARIO + '-' + item.JCD_KEY;
        let val = 'TaxYear/Scenario/JCDKey:' + item.TAX_YEAR + '/' + item.SCENARIO + '/' + item.JCD_KEY + '-' + item.SCENARIO_DESC + '/' + item.JURISDICTION_CODE + '/' + item.DOMAIN_NAME + '/' + item.CHANNEL_NAME + ']';
        let record = { keyItem: val };
        console.log("record ::", record);
        this.txYearScenarioJcdOptions.push(record);
        console.log("this.txYearScenarioJcdOptions ::", this.txYearScenarioJcdOptions);
      });
    } else {
      if (data.errorMessage === "no access") {
        this.AlertService.openSnackBar("Sorry you do not have access to do the requested action.", 'gtw-snackbar--red');
      } else {
        this.AlertService.openSnackBar("An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 'gtw-snackbar--red');
      }
    }
    console.log("getTaxYearScenarioJCDKey() returned Data ::", data);
  });

}



getData(this: any) {
  let data: any = {};
  this.businessAssignNav = [];
  this.busLoading = true;
  data.isBusHierAssign = 'Y';

  data.tax_year = this.taxYear;
  data.scenario = this.scenario;
  data.jcd_key = this.jcdKey;
  data.userSsoId = this.user_id;
  if (!data.tax_year || data.tax_year.length === "" || !data.scenario || data.scenario === "" || !data.jcd_key || data.jcd_key === "") {
    //getTxYearScenarioOptions();
    if (this.txYearScenarioJcdData.length > 0) {
      data.tax_year = this.txYearScenarioJcdData[0].TAX_YEAR;
      data.scenario = this.txYearScenarioJcdData[0].SCENARIO;
      data.jcd_key = this.txYearScenarioJcdData[0].JCD_KEY;



      this.isDataAvailable = true;
    }
  } else {
    this.isDataAvailable = true;
  }
  if (this.isDataAvailable) {
    this.UserAdminFactory.getUserBusiness(data).subscribe( (data: { callSuccess: string; jsonObject: string | any[]; errorMessage: string; }) => {
      this.busLoading = false;
      if (data.callSuccess === "1") {
        this.businessAssignNav = data.jsonObject;
        console.log("data.jsonObject ", data.jsonObject);
        if (data.jsonObject.length <= 0) {
          this.AlertService.openSnackBar("warning", "No businesses assigned", 4000);
          //+" Scenario: "+scenario+" JCD Key: "+jcdKey
        }

      } else {
        if (data.errorMessage === "no access") {
          this.AlertService.openSnackBar("error", "Sorry you do not have access to do the requested action.", 4000);
        } else {
          this.AlertService.openSnackBar("error", "An error has occurred, If you continue to experience this issue please visit the help page to contact support.", 4000);
        }
      }
    });
  } else {
    this.AlertService.openSnackBar("warning", "Tax Year/ Scenario / JCD Key not available.", 4000);
    this.busLoading = false;
  }
}

//getTxYearScenarioOptions();

setTxYearScenarioJcd() {
  console.log("setTxYearScenarioJcd ::", this.txyear_scenario_jcd);
  let itemKey = this.txyear_scenario_jcd.split('-')[0];
  console.log("itemKey ::", itemKey);

  let itemVal = itemKey.split(':')[1];
  console.log("itemVal ::", itemVal);

  this.taxYear = itemVal.split('/')[0];
  this.scenario = itemVal.split('/')[1];
  this.jcdKey = itemVal.split('/')[2];
  console.log("txYr ::", this.taxYear);
  console.log("scnrio ::", this.scenario);
  console.log("jcdKy ::", this.jcdKey);
  this.getData();

}

loadBizzHierarchyData() {
  console.log("loadBizzHierarchyData ::");
}

getEntities(this: any) {
  let data = {};
  this.enitiyAssignNav = [];
  this.entityLoading = true;
  this.UserAdminFactory.getUserEntity(data).subscribe( (data: { callSuccess: string; jsonObject: any; }) => {
    this.entityLoading = false;
    if (data.callSuccess === "1") {
      this.enitiyAssignNav = data.jsonObject;
    }
  });
}

//commented by mallesh
//getEntities()

getLegalEntities(searchKey: any) {
  /*this.legalEntities = [
        {name:"User" , display_name: "User"  },
        {name:"Editor" , display_name: "Editor"},
        {name:"Admin" , display_name: "Admin" },
        {name:"Developer" , display_name: "Developer" },
        {name:"Super Admin" , display_name: "Super Admin" }
    ];*/

  console.log("returning LEs for SearchKEY = ", searchKey);



  this.loading = true;
  let params = { action_code: 'x8vwlj', tax_year: '2016', search_string: searchKey };
  this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { jsonObject: any; }) => {
    this.loading = false;
    this.legalEntities = data.jsonObject;
    console.log("getLegalEntities() returned Data ::", data);
  });

  return this.legalEntities;
}

addLE(selectedItem: { DISPLAY_NAME: string; }, searchKey: string) {
  console.log("Selected LE ::", selectedItem);
  this.selectedLE = selectedItem.DISPLAY_NAME;
  //fire query to bring ME for selected LE
  this.enitiyAssignNav = this.legalEntities;

  this.loading = true;
  let params = { action_code: 'x8vwlj', tax_year: '2016', search_string: searchKey };
  this.JsonObjectFactory.getJSONObj(this.GENERAL_CONFIG.base_url + '/loadJsonObject', params, this.globalService, null, null).subscribe( (data: { jsonObject: any; }) => {
    this.loading = false;
    this.legalEntities = data.jsonObject;
    console.log("getLegalEntities() returned Data ::", data);
  });

}

cancelEntity() {
  this.getEntities();
}

//TODO: SEND BACK ENTITIES TO DB CALL
saveEntity() {

  //GET ALL CHECKED THAT DO NOT HAVE CHILDREN PROPERTIES;

  let selectedEntityList = _.filter(this.enitiyAssignNav, { 'selected': true });
  console.log(" selectedEntityList  ", selectedEntityList);

}

}
