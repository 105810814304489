import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Table} from 'primeng-lts/table';
import {Subscription, zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {DfMeDropdownComponent} from '../../../shared/components/df-me-dropdown/df-me-dropdown.component';
import {JSONExportHeader, JSONExportService} from '../../../shared/_services/JSONExport.service';
import {DiscModalViewerComponent} from '../../shared/components/disc-modal-viewer/disc-modal-viewer.component';
import {StmtIdBtnService} from '../../shared/components/stmt-id-btn/stmt-id-btn.service';
import {DSTSharedService} from '../../shared/dst-shared.service';
import {DSTChildFormState} from '../../shared/models/dst-child-form-state.model';
import {DSTStatement} from '../../shared/models/dst-statement.model';
import {CreateDisclosureService, DSTTaxYear} from '../../statement-tracker/create-disclosure/create-new/create-disclosure.service';
import {DSTDynamicComponentResolverService} from '../../statement-tracker/create-disclosure/shared/dst-dynamic-component-resolver.service';
import {TrackerTabsService} from '../../statement-tracker/tracker-tabs.service';
import {ReportsService} from '../reports.service';

@Component({
  selector: 'gtw-sec926-report',
  templateUrl: './sec-926-report.component.html',
  styleUrls: ['../../shared/styles/dst-shared.scss', './sec-926-report.component.scss']
})
export class Sec926ReportComponent implements OnInit, OnChanges, OnDestroy {

  // params to be passed from gtw-ui
  @Input('gtw-param') gtwInputParam!: string;

  @ViewChild(DiscModalViewerComponent) modalViewerComponent!: DiscModalViewerComponent;
  @ViewChild(DfMeDropdownComponent) dfMeDropdownComponent!: DfMeDropdownComponent;
  @ViewChild('dt') table!: Table;

  data: any[] = [];
  cols: any[] = [
    {
      field: 'STATEMENTID',
      header: 'Statement Id',
      hidden: false,
      width: '145px'
    },
    {
      field: 'PROJECT_NAME',
      header: 'Project',
      hidden: false,
      width: '105px'
    },
    {
      field: 'PRIMARYLEID',
      header: 'Transferor (Primary LEID)',
      hidden: false,
      width: '155px'
    },
    {
      field: 'PRIMARYENTITY',
      header: 'Transferor Name',
      hidden: false,
      width: '260px'
    },
    {
      field: 'PRIMARYBUSINESS',
      header: 'Transferor Business/ME',
      hidden: false,
      width: '260px'
    },
    {
      field: 'FILING_GROUP',
      header: 'Filing group',
      hidden: false,
      width: '260px'
    },
    {
      field: 'CHOICE1A_VAL',
      header: 'Transferee a specified 10%-owned foreign corporation not a CFC?',
      hidden: false,
      width: '275px'
    },
    {
      field: 'CHOICE2A_VAL',
      header: 'Transferor controlled by 5 or fewer domestic corporations?',
      hidden: false,
      width: '270px'
    },
    {
      field: 'CHOICE2B_VAL',
      header: 'Transferor remain in existence after the transfer?',
      hidden: false,
      width: '235px'
    },
    {
      field: 'SHOLDERNAME_VAL',
      header: 'If No, controlling shareholder',
      hidden: false,
      width: '270px'
    },
    {
      field: 'SHOLDEREIN_VAL',
      header: 'If No, controlling shareholder FEIN',
      hidden: false,
      width: '305px'
    },
    {
      field: 'CHOICE2C_VAL',
      header: 'Transferor is the parent corporation of an affiliated group?',
      hidden: false,
      width: '280px'
    },
    {
      field: 'PARENTCORP_VAL',
      header: 'Parent corporation',
      hidden: false,
      width: '275px'
    },
    {
      field: 'PARENTCORPEIN_VAL',
      header: 'Parent EIN',
      hidden: false,
      width: '235px'
    },
    {
      field: 'CHOICE2D_VAL',
      header: 'Basis adjustments under section 367(a)(4) made?',
      hidden: false,
      width: '225px'
    },
    {
      field: 'NAMEOFPARTNERSHIP_VAL',
      header: 'Deemed Transferor Partnership',
      hidden: false,
      width: '160px'
    },
    {
      field: 'EINOFPARTNERSHIP_VAL',
      header: 'Partnership FEIN',
      hidden: false,
      width: '175px'
    },
    {
      field: 'CHOICE3B_VAL',
      header: 'Partner pick up pro rata share of gain on transfer of partnership assets?',
      hidden: false,
      width: '295px'
    },
    {
      field: 'CHOICE3C_VAL',
      header: 'Partner disposing of its entire interest in the partnership?',
      hidden: false,
      width: '250px'
    },
    {
      field: 'CHOICE3D_VAL',
      header: 'Partner disposing of an interest in a limited partnership that is regularly traded on an established securities market?',
      hidden: false,
      width: '455px'
    },
    {
      field: 'secondaryLeid',
      header: 'Transferee (Secondary LEID)',
      hidden: false,
      width: '175px'
    },
    {
      field: 'SECONDARYENTITY',
      header: 'Transferee Name',
      hidden: false,
      width: '260px'
    },
    {
      field: 'ISFOREIGNCORPINGOLD_VAL',
      header: 'Transferee is in Legal Entity Database? If No, review Disclosure for Name/Address Completeness',
      hidden: false,
      width: '390px'
    },
    {
      field: 'ISTRANSFEREEFOREIGNCORPCTRLD_VAL',
      header: 'Transferee a foreign corporation that is a CFC?',
      hidden: false,
      width: '240px'
    },
    {
      field: 'DATEOFTRANSFER_VAL',
      header: 'Date of Transfer',
      hidden: false,
      width: '170px'
    },
    {
      field: 'TYPEOFPROPERTY_VAL',
      header: 'Property Transferred',
      hidden: false,
      width: '300px'
    },
    {
      field: 'FMVONDATEOFTRANSFER_VAL',
      header: 'FMV Amount or Arm\'s Length Price on Date of Transfer',
      hidden: false,
      width: '235px'
    },
    {
      field: 'COSTBASIS_VAL',
      header: 'Cost or Other Basis',
      hidden: false,
      width: '190px'
    },
    {
      field: 'GAINTRANSFER_VAL',
      header: 'Gain Recognized on Transfer',
      hidden: false,
      width: '257px'
    },
    {
      field: 'USEFULLIFE_VAL',
      header: 'Useful Life',
      hidden: false,
      width: '150px'
    },
    {
      field: 'INCOMEINCLUSIONOFTRANSFER_VAL',
      header: 'Income Inclusion for Year of Transfer',
      hidden: false,
      width: '316px'
    },
    {
      field: 'F926LINE11_VAL',
      header: 'Transferor transfer stock or securities subject to section 367(a) GRA?',
      hidden: false,
      width: '286px'
    },
    {
      field: 'F926LINE12A_VAL',
      header: 'Assets of a foreign branch  transferred to a foreign corporation?',
      hidden: false,
      width: '291px'
    },
    {
      field: 'F926LINE12B_VAL',
      header: 'Transferor a domestic corporation that transferred substantially all of the assets of a foreign branch to a specified 10%-owned foreign corporation?',
      hidden: false,
      width: '388px'
    },
    {
      field: 'F926LINE12C_VAL',
      header: 'Immediately after the transfer, domestic corporation a U.S. shareholder with respect to the transferee foreign corporation?',
      hidden: false,
      width: '331px'
    },
    {
      field: 'F926LINE12D_VAL',
      header: 'Transferred loss amount included in the gross income as required under section 91',
      hidden: false,
      width: '348px'
    },
    {
      field: 'F926LINE13_VAL',
      header: 'Transferor transfer property described in section 367(d)(4)?',
      hidden: false,
      width: '273px'
    },
    {
      field: 'F926LINE14A_VAL',
      header: 'Transferor transfer any intangible property that, at the time of transfer, had a useful life reasonably anticipated to exceed 20 years?',
      hidden: false,
      width: '361px'
    },
    {
      field: 'F926LINE14B_VAL',
      header: 'At the time of the transfer, did any of the transferred intangible property have an indefinite useful life?',
      hidden: false,
      width: '286px'
    },
    {
      field: 'F926LINE14C_VAL',
      header: 'Transferor choose to apply the 20-year inclusion period provided under Reg. Sec. 1.367(d)-1(c)(3)(ii) for any intangible property? ',
      hidden: false,
      width: '367px'
    },
    {
      field: 'F926LINE14D_VAL',
      header: 'Total estimated anticipated income or cost reduction attributable to the intangible property\'s or properties, as applicable, use(s) beyond the 20-year period',
      hidden: false,
      width: '410px'
    },
    {
      field: 'F926LINE15_VAL',
      header: 'Intangible property transferred considered or anticipated to be, at the time of the transfer or at any time thereafter, a platform contribution as defined in Reg. Sec. 1.482-7(c)(1)?',
      hidden: false,
      width: '473px'
    },
    {
      field: 'SUPPLEMENTALINFO_VAL',
      header: 'Supplemental Part III Information',
      hidden: false,
      width: '400px'
    },
    {
      field: 'F926LINE16A_VAL',
      header: 'Before Transfer: %Ownership by Transferor',
      hidden: false,
      width: '228px'
    },
    {
      field: 'F926LINE16B_VAL',
      header: 'After Transfer: %Ownership by Transferor',
      hidden: false,
      width: '217px'
    },
    {
      field: 'F926LINE17_VAL',
      header: 'Type of Nonrecognition Transaction',
      hidden: false,
      width: '190px'
    },
    {
      field: 'F926LINE18A_VAL',
      header: 'Gain Recognition under Sec. 904(f)(3)?',
      hidden: false,
      width: '190px'
    },
    {
      field: 'F926LINE18B_VAL',
      header: 'Gain Recognition under Sec. 904(f)(5)(F)?',
      hidden: false,
      width: '190px'
    },
    {
      field: 'F926LINE18C_VAL',
      header: 'Recapture under Section 1503(d)?',
      hidden: false,
      width: '170px'
    },
    {
      field: 'F926LINE18D_VAL',
      header: 'Exchange gain under Section 987?',
      hidden: false,
      width: '240px'
    },
    {
      field: 'F926LINE19_VAL',
      header: 'Transfer resulting from change in entity classification?',
      hidden: false,
      width: '251px'
    },
    {
      field: 'F926LINE20A_VAL',
      header: 'Domestic corporation make a distribution of property covered by section 367(e)(2)?',
      hidden: false,
      width: '276px'
    },
    {
      field: 'F926LINE20B_VAL',
      header: 'Total amount of gain or loss recognized pursuant to Regulations section 1.367(e)-2(b)',
      hidden: false,
      width: '276px'
    },
    {
      field: 'F926LINE20C_VAL',
      header: 'Domestic corporation not recognize gain or loss on the distribution of property because the property was used in the conduct of U.S. trade of business under Reg. Sec. 1.367(e)-2(b)(2)?',
      hidden: false,
      width: '507px'
    },
    {
      field: 'F926LINE21_VAL',
      header: 'Domestic corporation make a section 355 distribution of stock in a foreign controlled corporation covered by section 367(e)(1)?',
      hidden: false,
      width: '350px'
    },
  ];
  loading: boolean = true;
  taxyearLoaded: boolean = false;
  excelExportFileName: string;
  scrollBarHidden: boolean = false;

  taxYears: DSTTaxYear[] = [];
  taxYear: DSTTaxYear | undefined;
  selectedMes: string = '';
  activeRow!: DSTStatement;
  showModalViewer: boolean = false;
  private onDisCancelSubs!: Subscription;

  constructor(private cDSerivce: CreateDisclosureService,
              private dstSharedService: DSTSharedService,
              private stmtIdBtnService: StmtIdBtnService,
              private trackerTabsService: TrackerTabsService,
              private dCRService: DSTDynamicComponentResolverService,
              private jsonExportService: JSONExportService,
              private reportsService: ReportsService) {

    this.excelExportFileName = 'Reports-926_Report_' + this.dstSharedService.getExcelExportDateString();

    // local test only
    // this.dstSharedService.setBaseURLs({
    //   api: '/gtw',
    //   custom: '/custom',
    //   admin: '/admin',
    //   efile: '/gtw-efile-api',
    //   pdfEngine: '/pdf-engine',
    //   bulkPdfEngine: '/bulk-pdf-engine'
    // });
    // this.dstSharedService.setClientKey('0');
    // this.dstSharedService.setTaxYearScenario('2021', '40');
    // this.dstSharedService.setDSTUser('400394467', 5);
    // this.dstSharedService.setS3uploadButtonAccessUsers(['400394467']);
  }

  ngOnInit(): void {
    // local test only
    // const getTaxYears$ = this.cDSerivce.getTaxYears();
    // zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
    //   this.taxYears = data[0];
    //   this.setTaxYear('2020');
    //   this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
    //   this.loadReport();
    // });
    // // end of local test only
    
    this.onDisCancelSubs = this.dCRService.onDisclosureCancel.subscribe(() => {
      this.trackerTabsService.destroyModalTab();
      this.showModalViewer = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Angular Elements input is not defined on Init
    // see for details https://github.com/angular/angular/issues/29050
    // due to the above-mentioned reason, no api calls should be fired before input objects are received from gtw-ui
    if (!changes.gtwInputParam?.isFirstChange()) {
      try {
        const param = JSON.parse(changes.gtwInputParam.currentValue);
        this.dstSharedService.setClientKey(param.clientKey);
        this.dstSharedService.setDSTUser(param.ssoId, Number.parseInt(param.roleLevel));
        this.dstSharedService.setSystemLockUnlockStatus(param.systemLockStatus === "1", Number.parseInt(param.roleLevel));
        this.dstSharedService.setTaxYearScenario(param.taxYear, param.scenario);
        this.dstSharedService.setBaseURLs(param.baseURLs);
        this.dstSharedService.setS3uploadButtonAccessUsers(param.s3uploadButtonAccessUsers);
        const getTaxYears$ = this.cDSerivce.getTaxYears();
        zip(getTaxYears$).pipe(take(1)).subscribe((data) => {
          this.taxYears = data[0];
          this.setTaxYear(param.taxYear);
          this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
          this.loadReport();
        });
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.error('JSON parse Syntax Error' + e);
        }
        this.dstSharedService.showAlert('error', 'Missing gtw input params, please contact system administrator for assistance!');
      }
    }
  }

  ngOnDestroy(): void {
    this.onDisCancelSubs?.unsubscribe();
  }

  onTaxYearChange(): void {
    this.taxyearLoaded = true;
    this.dfMeDropdownComponent.loadMEsCustom(this.taxYear?.text.toString()!);
  }

  setTaxYear(taxYear: string): void {
    this.taxYear = this.taxYears.find((item: any) => {
      return item.text.toString() === taxYear;
    });
    this.taxyearLoaded = true;
  }

  selectedMeChanged(selected: string): void {
    this.selectedMes = selected;
  }

  loadReport(): void {
    this.data = [];
    this.loading = true;
    this.reportsService.loadForm926Report(this.taxYear?.text.toString()!, this.selectedMes || '').subscribe((data: any) => {
      this.data = data;
      this.data?.forEach((element) => { //params for opening statement
        element.statementId = element.STATEMENTID;
        element.taxYearId = element.TAXYEARID;
        element.formId = element.FORMID;
        element.formName = element.FORMNAME;
      });
      // wait for cdk scroll container to render and update scrollbar styling
      setTimeout(() => {
        this.changeScrollBarStyling();
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.data = [];
      this.dstSharedService.showAlert('error', 'Something went wrong while loading the 926 Report grid data, please try again!');
    }).add(() => {
      this.loading = false;
    });
  }

  private changeScrollBarStyling(): void {
    const cdkScrollerEles: HTMLCollectionOf<Element> = document.getElementsByTagName('cdk-virtual-scroll-viewport');
    if (cdkScrollerEles.length) {
      this.scrollBarHidden = ((cdkScrollerEles[0] as HTMLElement).scrollHeight > (cdkScrollerEles[0] as HTMLElement).offsetHeight) ? false : true;
    } else {
      this.scrollBarHidden = true;
    }
  }

  showViewUpdateStatement(rowData: any): void {
    this.activeRow = rowData;
    this.dstSharedService.setTableActionStatus(true);
    this.dstSharedService.setIsFiled(rowData.IS_FILED!);
    this.stmtIdBtnService.getChildStatements(rowData.STATEMENTID).subscribe((childFormStates: DSTChildFormState[]) => {
      this.showModalViewer = true;
      setTimeout(() => {
        this.modalViewerComponent.showMore('dst-disclosure-viewer', childFormStates);
      });
    }, (eMsg: string) => {
      console.log(eMsg);
      this.dstSharedService.showAlert('error', eMsg);
    }).add(() => {
      this.dstSharedService.setTableActionStatus(false);
    });
  }

  afterModalClosed(): void {
    this.trackerTabsService.destroyModalTab();
    this.showModalViewer = false;
  }

  exportToExcel(): void {
    const headersMap: JSONExportHeader[] = [
      {
        field: 'STATEMENTID',
        header: 'Statement Id',
        
      },
      {
        field: 'PROJECT_NAME',
        header: 'Project',
      },
      {
        field: 'PRIMARYLEID',
        header: 'Transferor (Primary LEID)',
      },
      {
        field: 'PRIMARYENTITY',
        header: 'Transferor Name',
      },
      {
        field: 'PRIMARYBUSINESS',
        header: 'Transferor Business/ME',
      },
      {
        field: 'FILING_GROUP',
        header: 'Filing group',
      },
      {
        field: 'LINE1_VAL',
        header: 'Transferee a specified 10%-owned foreign corporation not a CFC?',
      },
      {
        field: 'CHOICE2A_VAL',
        header: 'Transferor controlled by 5 or fewer domestic corporations?',
      },
      {
        field: 'CHOICE2B_VAL',
        header: 'Transferor remain in existence after the transfer?',
      },
      {
        field: 'SHOLDERNAME_VAL',
        header: 'If No, controlling shareholder',
      },
      {
        field: 'SHOLDEREIN_VAL',
        header: 'If No, controlling shareholder FEIN',
      },
      {
        field: 'CHOICE2C_VAL',
        header: 'Transferor is the parent corporation of an affiliated group?',
      },
      {
        field: 'PARENTCORP_VAL',
        header: 'Parent corporation',
      },
      {
        field: 'PARENTCORPEIN_VAL',
        header: 'Parent EIN',
      },
      {
        field: 'CHOICE2D_VAL',
        header: 'Basis adjustments under section 367(a)(4) made?',
      },
      {
        field: 'NAMEOFPARTNERSHIP_VAL',
        header: 'Deemed Transferor Partnership',
      },
      {
        field: 'EINOFPARTNERSHIP_VAL',
        header: 'Partnership FEIN',
      },
      {
        field: 'CHOICE3B_VAL',
        header: 'Partner pick up pro rata share of gain on transfer of partnership assets?',
      },
      {
        field: 'CHOICE3C_VAL',
        header: 'Partner disposing of its entire interest in the partnership?',
      },
      {
        field: 'CHOICE3D_VAL',
        header: 'Partner disposing of an interest in a limited partnership that is regularly traded on an established securities market?',
      },
      {
        field: 'secondaryLeid',
        header: 'Transferee (Secondary LEID)',
      },
      {
        field: 'secondaryEntity',
        header: 'Transferee Name',
      },
      {
        field: 'ISFOREIGNCORPINGOLD_VAL',
        header: 'Transferee is in Legal Entity Database? If No, review Disclosure for Name/Address Completeness',
      },
      {
        field: 'ISTRANSFEREEFOREIGNCORPCTRLD_VAL',
        header: 'Transferee a foreign corporation that is a CFC?',
      },
      {
        field: 'DATEOFTRANSFER_VAL',
        header: 'Date of Transfer',
      },
      {
        field: 'TYPEOFPROPERTY_VAL',
        header: 'Property Transferred',
      },
      {
        field: 'FMVONDATEOFTRANSFER_VAL',
        header: 'FMV Amount or Arm\'s Length Price on Date of Transfer',
        format: 'number',
      },
      {
        field: 'COSTBASIS_VAL',
        header: 'Cost or Other Basis',
        format: 'number',
      },
      {
        field: 'GAINTRANSFER_VAL',
        header: 'Gain Recognized on Transfer',
        format: 'number',
      },
      {
        field: 'USEFULLIFE_VAL',
        header: 'Useful Life',
      },
      {
        field: 'INCOMEINCLUSIONOFTRANSFER_VAL',
        header: 'Income Inclusion for Year of Transfer',
        format: 'number',
      },
      {
        field: 'F926LINE11_VAL',
        header: 'Transferor transfer stock or securities subject to section 367(a) GRA?',
      },
      {
        field: 'F926LINE12A_VAL',
        header: 'Assets of a foreign branch  transferred to a foreign corporation?',
      },
      {
        field: 'F926LINE12B_VAL',
        header: 'Transferor a domestic corporation that transferred substantially all of the assets of a foreign branch to a specified 10%-owned foreign corporation?',
      },
      {
        field: 'F926LINE12C_VAL',
        header: 'Immediately after the transfer, domestic corporation a U.S. shareholder with respect to the transferee foreign corporation?',
      },
      {
        field: 'F926LINE12D_VAL',
        header: 'Transferred loss amount included in the gross income as required under section 91',
      },
      {
        field: 'F926LINE13_VAL',
        header: 'Transferor transfer property described in section 367(d)(4)?',
      },
      {
        field: 'F926LINE14A_VAL',
        header: 'Transferor transfer any intangible property that, at the time of transfer, had a useful life reasonably anticipated to exceed 20 years?',
      },
      {
        field: 'F926LINE14B_VAL',
        header: 'At the time of the transfer, did any of the transferred intangible property have an indefinite useful life?',
      },
      {
        field: 'F926LINE14C_VAL',
        header: 'Transferor choose to apply the 20-year inclusion period provided under Reg. Sec. 1.367(d)-1(c)(3)(ii) for any intangible property? ',
      },
      {
        field: 'F926LINE14D_VAL',
        header: 'Total estimated anticipated income or cost reduction attributable to the intangible property\'s or properties, as applicable, use(s) beyond the 20-year period',
        format: 'number',
      },
      {
        field: 'F926LINE15_VAL',
        header: 'Intangible property transferred considered or anticipated to be, at the time of the transfer or at any time thereafter, a platform contribution as defined in Reg. Sec. 1.482-7(c)(1)?',
      },
      {
        field: 'SUPPLEMENTALINFO_VAL',
        header: 'Supplemental Part III Information',
      },
      {
        field: 'F926LINE16A_VAL',
        header: 'Before Transfer: %Ownership by Transferor',
        format: 'percent',
      },
      {
        field: 'F926LINE16B_VAL',
        header: 'After Transfer: %Ownership by Transferor',
        format: 'percent',
      },
      {
        field: 'F926LINE17_VAL',
        header: 'Type of Nonrecognition Transaction',
      },
      {
        field: 'F926LINE18A_VAL',
        header: 'Gain Recognition under Sec. 904(f)(3)?',
      },
      {
        field: 'F926LINE18B_VAL',
        header: 'Gain Recognition under Sec. 904(f)(5)(F)?',
      },
      {
        field: 'F926LINE18C_VAL',
        header: 'Recapture under Section 1503(d)?',
      },
      {
        field: 'F926LINE18D_VAL',
        header: 'Exchange gain under Section 987?',
      },
      {
        field: 'F926LINE19_VAL',
        header: 'Transfer resulting from change in entity classification?',
      },
      {
        field: 'F926LINE20A_VAL',
        header: 'Domestic corporation make a distribution of property covered by section 367(e)(2)?',
      },
      {
        field: 'F926LINE20B_VAL',
        header: 'Total amount of gain or loss recognized pursuant to Regulations section 1.367(e)-2(b)',
        format: 'number',
      },
      {
        field: 'F926LINE20C_VAL',
        header: 'Domestic corporation not recognize gain or loss on the distribution of property because the property was used in the conduct of U.S. trade of business under Reg. Sec. 1.367(e)-2(b)(2)?',
      },
      {
        field: 'F926LINE21_VAL',
        header: 'Domestic corporation make a section 355 distribution of stock in a foreign controlled corporation covered by section 367(e)(1)?',
      }
    ];

    this.jsonExportService.exportToExcel(this.excelExportFileName, this.table._value, headersMap);
  }

}
