import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import lodfilter  from 'lodash/filter';
import { HomePageContainerService } from '../home-page/home-page-container.service';
import { ScreenUpdateService } from '../home-page/home-page-screens-updates/screen-update.service';
import { ScreenDetailsModel } from '../home-page/screen-details/screen-details-model';
import { GtwElementsBaseUrlsModel } from '../shared/_models/gtw-elements-base-urls.model';
import { ActiveScreensService } from '../shared/_services/active-screens.service';
import { HeaderService } from './header.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
	selector: 'gtw-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@Output('load-modules') loadModules = new EventEmitter<any>();
	@Output('load-client') loadClient = new EventEmitter<any>();
	@Output('change-theme') changeTheme = new EventEmitter<any>();
	@Output('set-taxYearScenario') setTaxYearScenario = new EventEmitter<any>();
	@Output('set-scenario') setScenario = new EventEmitter<any>();
	@Output('set-businesses') setBusinesses = new EventEmitter<any>();
	@Output('sync-businesses') syncBusinesses = new EventEmitter<any>();
	@Output('global-params-changed') globalParamsChanged = new EventEmitter<any>();
	@Output('got-notifications') gotNotifications = new EventEmitter<any>();
	@Output('load-application') loadApplication = new EventEmitter<any>();
	@Output('load-screens') loadScreen =  new EventEmitter<{activity_key?:string, screen_key?:string}>();
	@Output('set-issue') setIssue = new EventEmitter<any>();
  @Output('logout-user') logoutUser = new EventEmitter<any>();

	baseURLs: any={};

	isDarkTheme: boolean = true;
	@Input('is-dark-theme')
	set _isDarkTheme(val:string){
		if('theme-black' == val ){
			this.isDarkTheme = true;
		}else{
			this.isDarkTheme=false;
		}
	}

	globalService : any;
	@Input('base-urls')
	set _base_urls(baseUrls: string){
		try{
			let urls:GtwElementsBaseUrlsModel = JSON.parse(baseUrls);
			if(Object.keys(urls).length > 0){
				this.baseURLs = urls;
			}
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	breadCrumbLink = [];
	@Input('bread-crumb')
	set _bread_crumb(keys : any){
		try{
		this.breadCrumbLink = JSON.parse(keys);
		}catch(error){
		console.log("Not an valid object");
		}
	}

	clientList : any = [{
		data : []
	}];

	applicationList : any = [{
		data : []
	}];

	taxYearList : any = [{
		data : []
	}];

	scenarioList : any = [{
		data: []
	}];

	issuesList : any = [{
		data: []
	}];
	isIssueListLoaded = false;

	taxYearListTemp : any = [{
		data : []
	}];

	scenarioListTemp : any = [{
		data: []
	}];

	USER_SETTINGS : any = {};
	SERVER_CONFIG : any = {};
	GENERAL_CONFIG : any = {};

	businessMeData : any = [];

	selectedApplication = '';
	selectedApplicationKey = 0;
	selectedTaxYear = '';
	selectedScenario : any = {};

	menuObj1 : any = [];
	childMenuObj : any = [];
	totalSelected = 0;
	selected : any = [];
	selectedArray : any  = [];
	showMenu = false;
	showRightMenu = false;
	filterss :any = [];
	public dropdownMenuActive = "";
	public showDropdownMenu = false;
	public logoutMenu = false;
	public navTogglerDiv = false;
	showBusinessMenu = false;
	showNotificationsPanel = false;
	showSubBusinessMenu = false;
	showFavPanel = false;
	isNotifPanel = false
	subBusinessMeData : any = []

	public search = false;
	public appList = false;
	public name = 'GTW';
	public selectionCursor = -1;
	client_logo = '';
	logo_client = '';
	user_logo = '';
	display_name = '';
	userInitials = '';
	singleMeGrpCount = 0;
	totalMeSelected = 0;
	tempTotalMeSelected = 0;
	oldScenarioTypeCode = '';
	messageCount: string = '0';
	clientKey?:number

	isFavLoaded = true;
	favScreens: any[] = [];
	allScreens!: ScreenDetailsModel[];

	@Input('header-data')
	set headerData(headerData : any){
		try{
			this.clientList = [{ data : [] }];
			this.applicationList = [{ data : [] }];
			this.taxYearList = [{ data : [] }];
			this.scenarioList = [{ data: [] }];

			this.taxYearListTemp = [{ data : [] }];
			this.scenarioListTemp = [{ data: [] }];

			this.globalService = JSON.parse(headerData);

			let globalParams = this.globalService.globalParams;
			let clientData = this.globalService.clientData;
			let channelData = this.globalService.channelData;
			let taxYearData = this.globalService.taxYearScenarioData;
			this.USER_SETTINGS = this.globalService.userSettingsData;
			this.SERVER_CONFIG = this.globalService.serverConfigData;
			this.GENERAL_CONFIG = this.globalService.generalConfigData;
			this.user_logo = this.globalService.userSettingsData.user.avatar_path;
			this.display_name = this.globalService.userSettingsData.user.short;
			this.userInitials = this.getInitials(this.display_name);

			//Iterate over Tax Year's Array and modify the object as per the appkit selector required object
			this.client_logo = clientData.client.client_logo;
			this.logo_client = this.baseURLs.admin +'/downloadClientLogo/'+clientData.client.client_key
			this.clientKey = clientData.client.client_key;
			clientData.clients.forEach( (client : any) => {
				let tempClientData = Object.assign({}, client)
				tempClientData.text = client.client_name;
				this.clientList[0].data.push(tempClientData);
			});

			//Application Data as per the appkit selector required object
			this.applicationList[0].data = channelData;
			this.applicationList[0].data.forEach( (channel : any) => {
				if(channel.selected){
					this.selectedApplication = channel.text;
					this.selectedApplicationKey = channel.value;
				}
			});
			this.headerService.setChannel(this.selectedApplicationKey);

			//Iterate over Tax Year's Array and modify the object as per the appkit selector required object
			taxYearData.values.forEach( (taxYear : any) => {
				let counter = 0;
				let tempTaxYear = Object.assign({}, taxYear)
				tempTaxYear.text = taxYear.label;
				tempTaxYear.selected = false;

				if(taxYear.value === this.globalService.taxYearScenarioData.selected.value){
					let taxYearObj = Object.assign({}, taxYear);
					taxYearObj.channel_key = this.selectedApplicationKey;
					tempTaxYear.selected = true;
					this.selectedTaxYear = taxYear.value;

					//Iterate over Scenario's Array and modify the object as per the appkit selector required object
					taxYear.scenarios.forEach( (scenario : any) => {
						let tempScenario = Object.assign({}, scenario)
						tempScenario.text = scenario.scenario_desc;
						tempScenario.selected = false;
						if(scenario.scenario === this.globalService.taxYearScenarioData.selected.scenario){
							tempScenario.selected = true;
							this.selectedScenario = tempScenario;
						};
						if(this.selectedApplicationKey == scenario.channel_key){
							//counter ++;
							this.scenarioList[0].data.push(tempScenario);
						}
					});
					this.taxYearList[0].data.push(tempTaxYear);
					this.taxYearListTemp[0].data.push(tempTaxYear);
					taxYearObj.scenarios = this.scenarioList[0].data;
					this.setTaxYearScenario.emit(taxYearObj);
				}else{
					taxYear.scenarios.forEach( (scenario : any) => {
						if(this.selectedApplicationKey == scenario.channel_key){
							counter ++;
						}
					});
				}
				if(counter > 0){
					this.taxYearList[0].data.push(tempTaxYear);
				}

			});
			this.taxYearListTemp[0].data = this.taxYearList[0].data;
			this.leftNavSelectedMe(this.baseURLs.api, this.selectedTaxYear, this.selectedScenario.scenario, this.selectedScenario.jcd_key);
			this.getAllScreens();
			this.getUnreadMessages();
			this.getSystemNotification();
			this.init();
		}catch(e){
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	public dropdownMenuData = [
		{ title: 'Channel/Tax Year/BE', content: 'Starbucks', active: true },
		{ title: 'Page menu one', content: 'Starbucks', active: false },
		{ title: 'Page menu two', content: 'Starbucks', active: false },
		{ title: 'Page menu three', content: 'Starbucks', active: false },
	];

	public logoutMenuData = [
		// {
		// 	title: 'Lock Screen',
		//  	url: 'settings'
		// },{
		// 	title: 'My Settings',
		//   	url: 'settings'
		// },
		{
			title: 'Admin Settings',
		    url: 'admin.users-list'
		},{
			title: 'User Request',
			url: 'userSettings.user-access-request',
		}
	];

	constructor(private headerService : HeaderService,
		 		private homePageContainerService: HomePageContainerService,
				private activeScreensService:ActiveScreensService,
				private screenUpdateService:ScreenUpdateService,
				private changeDetectorRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		const dom = document.body;
		dom.setAttribute('data-theme-logo',getComputedStyle(document.documentElement).getPropertyValue('--theme-color-logo').trim());
		this.dropdownMenuData.some(res => {
		if (res.active) {
			this.dropdownMenuActive = res.title;
		}
		return res.active
		});
		this.init();
	}

	init(){
		this.favScreens = [];
		this.isFavLoaded = false;
		this.screenUpdateService.allFavScreens$.subscribe((favScreens: any) => {
			if(favScreens && favScreens.length > 0) {
				this.favScreens = favScreens;
				this.changeDetectorRef.detectChanges();
			} else {
				this.favScreens = [{SCREEN_NAME:'No screen selected as favorite'}];
			}
		});
		}

	breadcrumbsDataPage = [
		{ title: 'First page', url: '' },
		{ title: 'Second page', url: '' },
		{ title: 'Third page', url: '' },
	];

	clickItem(item:any){
	}

	getInitials(name : string){
		let names = name.split(' ')
		let initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}

	onDrop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(this.favScreens, event.previousIndex, event.currentIndex);
			this.screenUpdateService.allFavScreens$.next(this.favScreens);

			this.changeDetectorRef.detectChanges();

			console.log('this.favScreens',this.favScreens);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}

	 }

	getAllScreens() {
		this.homePageContainerService.getAllScreens(this.baseURLs.api,this.USER_SETTINGS.user.client_key).subscribe((data: any) => {
			this.allScreens = data;
			this.homePageContainerService.screenList = this.allScreens;
		});
	}

	changeClient(client: any){
		this.loadClient.emit(client);
	}

	channelSelection(channel: any){
		this.taxYearList = [{data: [] }];
		let tempObj : any = [];

		this.selectedApplication = channel.change.text;
		this.selectedApplicationKey = channel.change.value;
		this.taxYearListTemp[0].data.forEach( (taxYear : any) => {
			let counter = 0;
			taxYear.scenarios.forEach( (scenario : any) => {
				if(this.selectedApplicationKey == scenario.channel_key){
					counter ++;
				};
			});
			if(counter > 0){
				tempObj.push(taxYear);
			};
		});
		this.headerService.setChannel(this.selectedApplicationKey);
		tempObj[0].selected = true;
		this.taxYearList[0].data = tempObj;
		let appKitSelectedObject = {
			change : this.taxYearList[0].data[0],
			items : this.taxYearListTemp,
			selected : [this.taxYearList[0].data[0]]
		}
		this.taxYearSelection(appKitSelectedObject);
		// let globalAppParams = {
		// 	channel : channel.change.value,
		// 	taxYear : this.selectedTaxYear,
		// 	scenarioKey : this.selectedScenario.scenario,
		// 	scenarioCode : this.selectedScenario.scenario_type_code
		// }


		//this.loadApplication.emit(channel.change.value);
		//this.loadApplication.emit(globalAppParams);
	}

	taxYearSelection(taxYear: any){
		let taxYearObj = Object.assign({}, taxYear.selected[0]);
		taxYearObj.channel_key = this.selectedApplicationKey;
		this.scenarioList = [{ data: [] }];
		this.selectedTaxYear = taxYear.selected[0].value;

		//this.setTaxYearScenario.emit(taxYear.change);

		let defaultCheckcounter = 0;

		taxYear.selected[0].scenarios.forEach( (scenario : any) => {
			let tempScenario = Object.assign({}, scenario)
			tempScenario.text = scenario.scenario_desc;
			tempScenario.selected = false;

			if(this.selectedApplicationKey == tempScenario.channel_key){
				//counter ++;
				this.scenarioList[0].data.push(tempScenario);
			}
		});

		taxYearObj.scenarios = this.scenarioList[0].data;

		this.scenarioList[0].data.forEach( (scenario : any) => {
			if(scenario.default_scenario === 'Y'){
				scenario.selected = true;
				this.selectedScenario = scenario;
				defaultCheckcounter ++;
			};
		})

		if(defaultCheckcounter == 0){
			this.scenarioList[0].data[0].selected = true;
			this.selectedScenario = this.scenarioList[0].data[0];
		}

		// taxYearObj.scenario = this.selectedScenario.scenario;
		// taxYearObj.scenario_desc = this.selectedScenario.scenario_desc;
		// taxYearObj.scenario_type_code = this.selectedScenario.scenario_type_code;

		//Set selected tax year and scenario values.
		this.setTaxYearScenario.emit(taxYearObj);

		let defaultScenario : any = lodfilter(this.scenarioList[0].data, {
			default_scenario: 'Y'
		});

		if (null != defaultScenario && defaultScenario != 'undefined' && defaultScenario.length > 0) {
			this.scenarioSelection(defaultScenario[0], false);
		}else{
			this.globalParamsChanged.emit();
			this.scenarioSelection(this.scenarioList[0].data[0], false);
		}
		//this.leftNavSelectedMe(this.baseURLs.api, taxYear.selected[0].value, taxYear.selected[0].scenario, this.scenarioList[0].data[0].jcd_key); // used to get heirarchy list based on the tax year selection
	}

	scenarioSelection(_selectedScenario : any, isMain : boolean) {
		//let selectedScenario : any = {};
		this.selectedScenario = {};
		let globalAppParams = {
			channel : this.selectedApplicationKey,
			taxYear : this.selectedTaxYear,
			scenarioKey : '',
			scenarioCode : '',
			scenario_desc : ''
		}
		this.reset();
		if(isMain){
			globalAppParams.scenarioKey = _selectedScenario.selected[0].scenario;
			globalAppParams.scenarioCode = _selectedScenario.selected[0].scenario_type_code;
			globalAppParams.scenario_desc = _selectedScenario.selected[0].scenario_desc;
		///	this.setScenario.emit(globalAppParams);
			this.selectedScenario = _selectedScenario.selected[0];
			//this.selectedScenario = _selectedScenario.selected[0];
		}else{
			globalAppParams.scenarioKey = _selectedScenario.scenario;
			globalAppParams.scenarioCode = _selectedScenario.scenario_type_code;
			globalAppParams.scenario_desc = _selectedScenario.scenario_desc;
		//	this.setScenario.emit(globalAppParams);
			this.selectedScenario = _selectedScenario;
			//this.selectedScenario = _selectedScenario;
		}

		this.taxYearList[0].data.forEach( (tax_year : any) => {
			if(tax_year.selected){
				// vm.tax_year.selected.scenario_desc = _selectedScenario.scenario_desc;
				// vm.tax_year.selected.scenario = _selectedScenario.scenario;
				tax_year.scenario_desc = this.selectedScenario.scenario_desc;
				tax_year.scenario = this.selectedScenario.scenario;
				tax_year.scenario_type_code = this.selectedScenario.scenario_type_code;
			}
		});

		this.setScenario.emit({globalAppParams:globalAppParams,taxYearList: this.taxYearList[0].data});
		this.getIssues();

		if(this.oldScenarioTypeCode.length === 0 || (this.oldScenarioTypeCode.length > 0 && this.oldScenarioTypeCode !== this.selectedScenario.scenario_type_code)) {
			//this.getUserMenu();
			this.oldScenarioTypeCode = this.selectedScenario.scenario_type_code ? this.selectedScenario.scenario_type_code : '';
		}

		this.globalParamsChanged.emit();
		this.leftNavSelectedMe(this.baseURLs.api, this.selectedTaxYear, this.selectedScenario.scenario, this.selectedScenario.jcd_key);
	}

	issueSelection(_selectedIssue : any){
		let issueData = {
			data : {},
			selectedIssueData : _selectedIssue.selected[0],
			setAllParams : false,
			setOnlyId : true,
			emptyIssue : true
		};
		this.setIssue.emit(issueData);
	};

	leftNavSelectedMe(url : string, selectedTaxYear : string, selectedScenario : string, jcd_key : any) {
		this.filterss.loading = true;
		this.headerService.getBizHeirarchy(url, selectedTaxYear, selectedScenario, jcd_key).subscribe((data: any) => {
			this.filterss.selected = [];
			this.filterss.loading = false;
			this.filterss.values = data.jsonObject;
			this.setBusinesses.emit(this.filterss);
			this.setTreeParent((data.jsonObject),'children' );
			this.menuObj1 = data.jsonObject;
			this.getTotalSelected();
		},
		error => {
			console.log(error);
			// Deciede whether to configure alert here on use the same in the AngularJS App.
			//AlertService.add('error', 'Global level ME seletions list failed, please contact support');
		},() => {
			console.log('Completed all tabs');
		});
	}

	getIssues() {
		this.isIssueListLoaded = false;
		this.headerService.getIssues(this.baseURLs.api, this.selectedTaxYear, this.selectedScenario.scenario).subscribe((data: any) => {
			if(data.callSuccess == 1) {
				let issueData = {
					data : {},
					selectedIssueData : '',
					setAllParams : false,
					setOnlyId : false,
					emptyIssue : false
				};
				if(data.issue.length > 0){
					let tempIssueList = data.issue;
					tempIssueList.forEach( (issue : any) => {
						issue.text = issue.issue_short_desc;
					});
					tempIssueList[0].selected = true;
					this.issuesList[0].data = tempIssueList;

					//Set Issue Data Globally.
					issueData.data = this.issuesList[0].data;
					issueData.selectedIssueData = this.issuesList[0].data[0];
					issueData.setAllParams = true;
					this.setIssue.emit(issueData);
				}else{
					this.setIssue.emit(issueData);
				}
			} else {
				console.log('Global level issue id list failed, please contact support');
				//AlertService.add('error', 'Global level issue id list failed, please contact support');
			}
		},
		error => {
			console.log(error);
			// Deciede whether to configure alert here on use the same in the AngularJS App.
			//AlertService.add('error', 'Global level ME seletions list failed, please contact support');
		},() => {
			console.log('Completed all tabs');
			this.isIssueListLoaded = true;
		});
	};

	setTreeParent(_collection : any,_children : any){
		for (var i = 0; i < _collection.length; i++) {
			var thisChildren =  _collection[i][_children];
			if( thisChildren && thisChildren.length ){
				this.setTreeParent(thisChildren,_children);
			}

			for(var n in thisChildren){
				thisChildren[n].parent = _collection[i];
			}
		}
	}

	reset(){
		this.totalSelected = 0;
		this.menuObj1 = [];
		this.childMenuObj = [];
		this.selected  = [];
		this.selectedArray  = [];
		this.showMenu = false;
		this.showRightMenu = false;
		this.showSubBusinessMenu = false;
		this.filterss.selected = [];
		this.syncBusinesses.emit(this.filterss);
	}

	change() {
		this.createdSelectedArray();
		this.filterss.selected = this.selectedArray;
		this.syncBusinesses.emit(this.filterss);
	}

	createdSelectedArray(){
		this.selectedArray = [];
		for (var i = 0; i < this.selected.length; i++) {
			let newObj :any = {}
			newObj.VALUE = this.selected[i];
			newObj.parnet = this.selected[i];
			newObj.level = this.selected[i];
			this.selectedArray.push(newObj);
		}
	}

	setTreeAttribute(_collection:any,_predicate:any,_children:any,value:any){
		for (var i = 0; i < _collection.length; i++) {
			var  n = _collection[i][_predicate];
			var children = _collection[i][_children];
			if(children && children.length ){
				this.setTreeAttribute(children,_predicate,_children,value );
			}
			_collection[i][_predicate] = value;
		}
	}

	selectParentMe(item:any){
		this.setTreeAttribute(this.menuObj1,'active','children',false);
		item.active = true;
		this.childMenuObj = item.children;
		this.showSubBusinessMenu = true;
	};

	toggleMenu(){
		this.showMenu = !this.showMenu;
		if(!this.showMenu ){
			this.showRightMenu = false;
		}
	}

	selectAll(){
		this.setTreeAttribute(this.menuObj1,'selected','children',true);
		this.getSelectedAll();
		this.change();
	}

	getSelectedAll(){
		this.selected  = [];
		var selected = 0;
		let tempSelectedArray :any = [];

		this.menuObj1.forEach( (item : any) => {
			if(item.selected ){
				selected++;
				//this.selected.push(item.key);
				tempSelectedArray.push(item.key);
			}
			if(item.children && item.children.length > 0) {
				item.numSelected = recursiveSelected(item);
			}
		})

		function recursiveSelected(collection:any){
			var selected = 0;
			function recursiveSelectedInner(collection:any) {
				collection.children.forEach( (item : any) => {
					if(item.selected){
						//this.selected.push(item.key);
						tempSelectedArray.push(item.key);
						selected++;
					}
					if(item.children && item.children.length > 0) {
						recursiveSelectedInner(item);
					}
				});
			}
			recursiveSelectedInner(collection)
			return selected;
		}
		this.selected = tempSelectedArray;
		return selected;

	}

	unselectAll(){
		this.setTreeAttribute(this.menuObj1,'selected','children',false);
		this.getSelected();
		this.change();
	}

	getSelected(){
		this.selected  = [];
		let selected = 0;
		let tempSelectedArray :any = [];

		this.menuObj1.forEach( (item : any) => {
			if(item.selected ){
				selected++;
				//this.selected.push(item.key);
				tempSelectedArray.push(item.key);
			}
			if(item.children && item.children.length > 0) {
				item.numSelected = recursiveSelected(item);
			}
		})

		function recursiveSelected(collection : any){
			var selected = 0;
			function recursiveSelectedInner(collection : any) {
				collection.children.forEach( (item : any) => {
					if(item.selected ){
						//this.selected.push(item.key);
						tempSelectedArray.push(item.key);
						selected++;
					}
					if(item.children && item.children.length > 0) {
						recursiveSelectedInner(item);
					}
				});
			}
			recursiveSelectedInner(collection)
			return selected;
		}
		this.selected = tempSelectedArray;
		return selected;
	}

	checkParent(_item : any){
		let allSelected = true;
		if( _item.parent ){
			_item.parent.children.forEach( (item : any) => {
				if(!item.selected ){
					 allSelected = false;
				}
			});
			_item.parent.selected = allSelected;

			if( _item.parent.parent ){
				this.checkParent(_item.parent);
			}
		}
	}

	selectedMe(event: any, collection: any, isParent : boolean){
		event.preventDefault();
		let selected = false;
		this.showSubBusinessMenu = true;

		//Temporary fix for checking the checkbox value as the checked property is not returninig the model value.
		collection.selected = !collection.selected;
		if(collection.selected){
			selected = collection.selected;
		}

		function setEm(_collection: any){
			if(_collection.children && _collection.children.length > 0){
				_collection.children.forEach( (item : any) => {
					item.selected = selected;
					if(item.children && item.children.length > 0){
						setEm(item);
					}
				});
			};
		}

		setEm(collection);
		this.checkParent(collection);
		this.getSelected();
		this.change();

		if(isParent){
			this.selectParentMe(collection);
		}
	}

	setMeSelection(me : any, value : boolean){
		//this.tempTotalMeSelected = 0;
		me.forEach( (me : any) => {
			this.tempTotalMeSelected++;
			me.selected = value;
			if(me.children){
				//this.totalMeSelected = value ? this.totalMeSelected + me.children.length : this.totalMeSelected - me.children.length;
				this.setMeSelection(me.children, value);
			};
		});
	};

	getAllMeCounts(me : any){
		let meCount = 0;
		//if(me.children){
		//	meCount += me.children.length;
		me.forEach( (me : any) => {
			meCount++;
			if(me.children){
				getMeCounts(me.children);
			}
		});

		function getMeCounts(me: any){
			me.children.forEach( (me : any) => {

				if(me.children){
					meCount += me.children.length;
					getMeCounts(me.children);
				};
			});
		};

		//}else{
		//	meCount += 1;
		//}
		return meCount;
	};

	meSelection(value: boolean){
		this.tempTotalMeSelected = 0;
		//this.totalMeSelected = 0;
		//this.totalMeSelected = this.getAllMeCounts(this.businessMeData);
		//this.setMeSelection(this.businessMeData, value);

		this.businessMeData.forEach( (me : any) => {
			//meCount++;
			//if(me.children){
				this.selectedMe(null, me, true);
			//}
		});

		if(value){
			this.totalMeSelected = this.tempTotalMeSelected;
			console.log(this.singleMeGrpCount);
		}else{
			this.totalMeSelected = 0;
		}
	}

	getTotalSelected(){
		this.totalSelected = this.getSelected();
	}

	selectedSubMe(subMe: any){
		console.log(subMe);
	}

	selectedSubChildMe(subChildMe: any){
		console.log(subChildMe);
	}

	isActive(route: string) {
		this.loadModules.emit(route);
		this.logoutMenu=false;
	}

	showDropdownMenuFun() {
		this.showDropdownMenu = !this.showDropdownMenu;
	}

	activeDropdownMenu(item: any) {
		this.dropdownMenuData.forEach(res => res.active = false);
		item.active = true;
		this.dropdownMenuActive = item.title;
		this.showDropdownMenuFun();
	}

	showLogoutMenu() {
		this.logoutMenu = !this.logoutMenu;
	}

	setTheme(item: 'white'|'black') {
		console.log("Swith theme api request");
		this.headerService.setTheme(this.baseURLs.admin,item).subscribe((res:any)=>{
			if(res?.callSuccess == "1" && res?.errorMessage == null){
				console.log("Theme was changed successfully.")
				this.isDarkTheme = !this.isDarkTheme;
				this.changeTheme.emit(item);
			}
		},
		err=>{
			console.log("Theme change failed");
		});
	}

	singOut() {
		this.logoutMenu = false;

		this.headerService
			.signout(this.baseURLs.api)
			.subscribe(
				(response : any)=>{
          this.logoutUser.emit({});
				}
			)

	}

	showNavTogglerDiv() {
		this.navTogglerDiv = !this.navTogglerDiv;
	}

	activeNavToggler() {
		this.navTogglerDiv = false;
	}

	logoutMenuClose(e: any) {
		switch (e.which) {
		case 9:
			this.logoutMenu = false;
			break;
		}
	}

	navTogglerDivClose(e: any) {
		switch (e.which) {
		case 9:
			this.navTogglerDiv = false;
			break;
		}
	}

	favMenuClose(e: any) {
		switch (e.which) {
		case 9:
			this.showFavPanel = false;
			break;
		}
	}

	onKeyDropDownMenu(event: any) {
		switch (event.which) {
		case 9:
			this.showDropdownMenu = false;
			break;
		case 13:
			event.preventDefault();
			if (this.selectionCursor !== -1 && this.showDropdownMenu) {
			this.dropdownMenuData.forEach(res => res.active = false);
			this.dropdownMenuData[this.selectionCursor].active = true;
			this.dropdownMenuActive = this.dropdownMenuData[this.selectionCursor].title;
			this.showDropdownMenuFun();
			this.selectionCursor = -1;
			} else {
			this.showDropdownMenuFun();
			this.selectionCursor = -1;
			}
			break;
		case 38:
			event.preventDefault();
			if (!this.showDropdownMenu) {
			this.showDropdownMenuFun();
			}
			this.selectionCursor = this.selectionCursor < 1 ? 0 : this.selectionCursor - 1;
			break;
		case 40:
			event.preventDefault();
			if (!this.showDropdownMenu) {
			this.showDropdownMenuFun();
			}
			this.selectionCursor = this.selectionCursor > this.dropdownMenuData.length - 2 ? this.dropdownMenuData.length - 1 : this.selectionCursor + 1;
			break;
		}
	}

	//App Notifications related code
	activeSysMsgData : any = [];
	selectedSysMsgData : any = {};
	counter = 0;
	isMessageCountLoading : boolean = false;
	taskCount = 0;
	firstTime = true;
	reloadMessage = false;
	reloadTask = false;
	checkNotif = 0;

	getSystemNotification() {
		let data = {
			sso_id: this.USER_SETTINGS.user.sso_id,
			client_key: this.USER_SETTINGS.user.client_key,
			user_type: this.USER_SETTINGS.user.client.user_type_key
		}
		let notifData = {
			data : {},
			selectedSysMsgData : this.selectedSysMsgData,
			onlyNotifMsg : false,
			emptyNotifData : false
		};
		this.headerService.getSystemNotifByUser(this.baseURLs.admin, {data}).subscribe((data: any) => {
			if (data && data.callSuccess == 1) {
				this.activeSysMsgData = data.notif_list;
				if(data.notif_list && data.notif_list.length>0){
					this.selectedSysMsgData = data.notif_list[0];
					data.notif_list[0].notif_msg_body = JSON.parse(data.notif_list[0].notif_msg_body);
					notifData.data = data;
					notifData.selectedSysMsgData = this.selectedSysMsgData;
					notifData.onlyNotifMsg = true;
					this.gotNotifications.emit(notifData);
				}else{
					this.gotNotifications.emit(notifData);
				}
			}
		},
		error => {
			console.log(error);
			// Deciede whether to configure alert here on use the same in the AngularJS App.
			//AlertService.add('error', 'Global level ME seletions list failed, please contact support');
		},() => {
			console.log('Completed all tabs');
		});
	}

	/* to get the Unread Message count and Incompleted Tasks count  */
	getUnreadMessages() {
		if (this.counter > Number(this.SERVER_CONFIG.configSettings.max_no_of_failed_atempts)) {
			return;
		}

		if (this.isMessageCountLoading)
			return;
			this.isMessageCountLoading = true;

		this.headerService.getJSONObj(this.baseURLs.api,).subscribe((data: any) => {
			setTimeout(() => {
				this.isMessageCountLoading = false;
			}, 5000);

			if (data.unreadMessageCount === 'success') {
				let messageCount = data.totalUnreadMessageCount;
				let taskCount = Number(data.totalUnCompleteTaskCount);
				if(data == false){
					window.location.href = this.GENERAL_CONFIG.logout_idam_session;
			   }
				if (this.firstTime) {
					this.messageCount = messageCount;
					this.taskCount = taskCount;
					this.firstTime = false;
				}

				if (this.messageCount !== messageCount) {
					this.reloadMessage = true;
					this.messageCount = messageCount;
				} else {
					this.reloadMessage = false;
				}
				/* starts added on 7/25/2019 */
				if(this.taskCount !== taskCount){
					this.reloadTask = true;
					this.taskCount = taskCount;
				}else{
					this.reloadTask = false;
				}
				/* ends here*/
			} else {
				this.counter++;
			}
		},
		error => {
			console.log(error);
		},() => {
			console.log('Completed all tabs');
		});
	}

	notifCong(){
		if(this.checkNotif == 1){
			let data = {
				sys_notif_key : this.selectedSysMsgData.notif_msg_key,
				sso_id: this.USER_SETTINGS.user.sso_id,
				client_key: this.USER_SETTINGS.user.client_key
			}
			let notifData = {
				data : {},
				selectedSysMsgData : {},
				onlyNotifMsg : false,
				emptyNotifData : true
			};
			this.headerService.saveSystemDontShowMsg(this.baseURLs.api, data).subscribe((data: any) => {
				if(data && data.callSuccess == 1){
					if(this.activeSysMsgData && this.activeSysMsgData.length > 1) {
						//Remove the notifications only active list is morethan one and sets the first notification from the active list, otherwise will remove the notification..
						this.activeSysMsgData.forEach( (item : any) => {
							if(item && item.notif_msg_key == this.selectedSysMsgData.notif_msg_key) {
								this.activeSysMsgData.splice(item, 1);
							}
						});

						this.selectedSysMsgData = this.activeSysMsgData[0];
					} else {
						this.activeSysMsgData = [];
						this.gotNotifications.emit(notifData);
					}
				}
				this.checkNotif = 0;
			})
		}
	}

	cancelNotifModal(){
		let notifData = {
			data : {},
			selectedSysMsgData : {},
			onlyNotifMsg : false,
			emptyNotifData : true
		};
		this.gotNotifications.emit(notifData);
	}

	closeBusinessDropdown(event:any){
		this.showBusinessMenu= false;
	}

	loadClickedScreen(clickedScreen:any){
		if( Object.keys(clickedScreen).indexOf('SCREEN_NAME') < 0 && Object.keys(clickedScreen).indexOf('screenKey') < 0 ){
			this.allScreens.forEach((screen) => {
				if(screen.SCREEN_NAME == clickedScreen.name){
					this.activeScreensService.markOpenSelectedScreen(screen);
				this.loadScreen.emit({activity_key:screen.ACTIVITY_KEY, screen_key:screen.SCREEN_KEY});
				}
			});
		}else{
			let key = clickedScreen?.screenKey || clickedScreen?.SCREEN_KEY;
			this.allScreens.forEach(screen => {
				if(screen.SCREEN_KEY == key){
					this.activeScreensService.markOpenSelectedScreen(screen);
					this.loadScreen.emit({activity_key:screen.ACTIVITY_KEY, screen_key:screen.SCREEN_KEY});
				}
			});
		}
	  }

}
