<mat-dialog-content>
    <div class="gt-page-body">
        <div class="row" style="margin: 0; border: 1px solid #ccc; border-radius: 3px;">
          <div class="full-panel">
            <!-- Client/Tax Year Filters and JCD Scenario Table -->
            <div class="col-md-4">
              <div class="client-content">
                <label>Client: <span>{{ userSettings.user.client.client_name }}</span></label>
                <!-- <span style="flex: 1; margin-top: 3px;">Client: </span>
                <input type="text" class="form-control" [(ngModel)]="userSettings.user.client.client_name" [disabled]="true"> -->
                <!-- <select style="flex: 8" [disabled]="true" id="clientId" class="form-control input-sm" name="clientId"
                  (ngModelChange)="resetData(client_key)" [(ngModel)]="client_key"
                  [ngModelOptions]="{standalone: true}"
                  [ngValue]="client.client_key as client.client_name for client in clients" required></select> -->
                <!-- <span class="form-error-container" [hidden]="!clientId.dirty || !clientId.invalid || templateFrom.submitted">Client is required.</span> -->
              </div>
      
              <div style="margin: 10px 0px 0px 0px;" *ngIf="selectedGrpObjKeys.length > 0">
                <ap-accordions [isGreyBackground]="false" [isTransparentAccordion]="false" class="b-a-ap-accordions" [multiOpen]="true"
                  closeOthers="true" id="my-ap-accordions">
                  <ap-accordions-children-item *ngFor="let anObj of bizzHierGrpObjDetails; let $index = index"  [isIconLeft]="true" [index]="$index" [open]="anObj.open" [title]="getName($index, anObj)">
                    <!--  -->

                      <!-- <input (click)="updateSelectedGroupObjKeys(anObj.GROUP_OBJ_KEY, anObj.TAX_YEAR)"
                        [checked]="anObj.selected" type="radio" id="chk_{{ anObj.GROUP_OBJ_KEY }}"> -->
                      
                      

                    <table class="table a-accordion-content a-table-cont">
                      <thead>
                        <tr>
                          <th>Scenario</th>
                          <th>JCD Key</th>
                          <!-- <th>Jurisdiction</th> -->
                          <th>Application</th>
                          <!-- <th>Domain</th> -->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let anObj of bizzHierGrpObjDetails | filterObj: anObj.GROUP_OBJ_KEY">
                          <!--  -->
                          <td><span class="badge btn-info">{{ anObj.SCENARIO }}</span>&nbsp;{{ anObj.SCENARIO_DESC }}</td>
                          <td><span class="badge btn-info">{{ anObj.JCD_KEY }}</span></td>
                          <!-- <td>{{ anObj.JURISDICTION_KEY }}&nbsp;-&nbsp;{{ anObj.JURISDICTION_CODE }}</td> -->
                          <td>{{ anObj.CHANNEL_KEY }}&nbsp;-&nbsp;{{ anObj.CHANNEL_NAME }}</td>
                          <!-- <td>{{ anObj.DOMAIN_KEY }}&nbsp;-&nbsp;{{ anObj.DOMAIN_NAME }}</td> -->
                          <td><i class="fa fa-external-link fa-link-style" [ngClass]="{'active-color': anObj.selected}"
                              (click)="updateSelectedGroupObjKeys(anObj.GROUP_OBJ_KEY, anObj.TAX_YEAR, anObj.SCENARIO)"
                              id="chk_{{ anObj.GROUP_OBJ_KEY }}"></i></td>
                        </tr>
                      </tbody>
                    </table>
                  </ap-accordions-children-item>
                </ap-accordions>
              </div>
              <br>
              <br>
              <div class="row" style="margin: 10px 0px 0px 20px" *ngIf="selectedGrpObjKeys.length === 0">
                <label><strong>No Business Hierarchy Found</strong></label>
              </div>
            </div>
            <!-- Place holder for Bizz Hierarchy Preview -->
            <div class="col-md-8">
              <div class="row" *ngIf="selectedGrpObjKeys.length > 0">
                <div class="right-panel" *ngFor="let item of bizzHierarchies">
                  <div class="col-md-12" *ngIf="item.visible">
                    <div class="panel panel-default">
                      <!-- Default panel contents -->
                      <div class="panel-heading">Selected Business Hierarchy<!---<b>{{item.group_obj_key}} </b>-->
                        <div class="pull-right">
                          <button class="btn  btn-info btn-xs" type="button" (click)="saveBus(item.group_obj_key)">
                            <span *ngIf="loading"><i class="fa fa-spinner fa-pulse"></i></span> Save
                          </button>
                          <button class="btn btn-warning btn-xs" type="button" (click)="cancelBus()">Cancel</button>
                        </div>
      
                      </div>
                      <div class="gt-dark-cnt">
                        <div class="gt-dark-header"></div>
                        <div>
                          <gt-business-assign-menu  [callBackService]="'UserAdminFactory'" [menuData]="item.value"
                            [renderingMode]="previewMode" [userId]="logged_in_user" [loading]="loading"
                            [taxYear]="taxYear" [reportingMonth]="reportingMonth" [clientKey]="client_key"
                            [groupObjKey]="0">replaces with bizz hierarchy preview</gt-business-assign-menu>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- end of shadow -->
            </div>
            <ap-loading id="overlay" *ngIf="busHierarchyLoading || busHierarchiesLoading" [size]="'24px'" [colorName]="'a-primary'">
            </ap-loading>
          </div>
        </div>
      </div>

</mat-dialog-content>

  