import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//import { StateService } from '@uirouter/core';
//import { AppService } from '../../../app.service';
//import { GridEventService } from '../../../core/grid/services/grid-event.service';
import { GtwSnackbarService } from '../../../shared/_services/gtw-snackbar.service';
//import { setFormData } from '../../shared/admin.utilities';
//import { UserAdminService } from '../users-component/user-admin.service';
import { FormControl } from '@angular/forms';
import { UserAccessRequestService } from './user-access-request.service';
import { environment } from 'projects/gtw-elements/src/environments/environment';
//import { access } from 'fs';

@Component({
	selector: 'gtw-user-access-request',
	templateUrl: './user-access-request.component.html',
	styleUrls: ['./user-access-request.component.scss']
})
export class UserAccessRequestComponent implements OnInit {
	user_id: any;
	user!: any;
	loading!: boolean;
	client!: any;
	clientList!: any[];
	settings: any;
	defaultClientData!: any[];
	crudLoading!: boolean;
	user_types!: { label: string; value: number }[];
	role_types!: string[];
	client_user_types!: { label: string; user_type_key: number }[];
	active!: any;
	user_type!: any;
	role_type: any;
	client_active: any;
	isLoadingEmail_to: boolean = false;
	filteredOptions: any;
	clientSelected = new FormControl();
	noRecords: any = false;

	@Input() rowData: any;
	@Output() emitEvent = new EventEmitter<string>();
	selectedClient: any;
	user_name: any;
	GENERAL_CONFIG : any;
	pendingRequests: any;
	sendRequestBtn: any;
	globalService: any;
	appService: any = {
		USER_SETTINGS: '',
		SERVER_CONFIG: ''
	};

	@Input('header-data')
	set headerData(headerData : any){
		try{
			this.globalService = JSON.parse(headerData);

			this.appService.USER_SETTINGS = this.globalService.userSettingsData;
			this.appService.SERVER_CONFIG = this.globalService.serverConfigData;
			this.GENERAL_CONFIG = this.globalService.generalConfigData;
			this.init();
		}catch(e){			
			console.log("Input Not yet stable. Still waiting to get resolved.");
		}
	}

	constructor(
		//private stateService: StateService,
		//private appService: AppService,
		//private UserAdminFactory: UserAdminService,
		private AlertService: GtwSnackbarService,
		//private gridEventService: GridEventService,
		private userAccessRequestService: UserAccessRequestService
	) {}

	ngOnInit(): void {
		//this.GENERAL_CONFIG = environment;
		console.log(this.rowData);
		
		this.selectedClient = {
			CLIENT_NAME: '',
			CLIENT_KEY: ''
		};
		this.user = {
			user_type : ''
		};
		this.loading = false;
		this.client = {};
		this.clientList = [];
		
		this.defaultClientData = [];
		this.crudLoading = false;
		
		this.user_types = [
		{ label: 'User', value: 1 },
		{ label: 'Editor', value: 2 },
		{ label: 'Admin', value: 3 },
		{ label: 'Developer', value: 4 },
		{ label: 'Super Admin', value: 5 },
		];

		this.role_types = ['IT', 'External', 'AC', 'Functional'];

		this.client_user_types = [
		{ label: 'User', user_type_key: 1 },
		{ label: 'Editor', user_type_key: 2 },
		{ label: 'Admin', user_type_key: 3 },
		{ label: 'Developer', user_type_key: 4 },
		{ label: 'Super Admin', user_type_key: 5 },
		];

		this.active = '0';
		this.user_type = {};
		this.sendRequestBtn = false;
		
	}

	init(){
		this.user_id = this.appService.USER_SETTINGS.user.sso_id;
		this.user_name = this.appService.USER_SETTINGS.user.display_name;
		this.settings = this.appService.SERVER_CONFIG.configSettings;
		this.getData();
	}

	DeleteImg() {}

	removeSelection(i: any){
		this.clientList.splice(i, 1);
	}

	getClient(val: any) {
		this.isLoadingEmail_to = true;
		if (val.length > 3) {
			// this.filteredOptions = [{
			// 	CLIENT_KEY: '0',
			// 	CLIENT_NAME: 'General Electric'
			// },{
			// 	CLIENT_KEY: '1',
			// 	CLIENT_NAME: 'Jacobs'
			// }];

			//     return this.templateAssignFactory.getUser(val).then((clients: any) => {
			//     console.log(clients);
			//     this.isLoadingEmail_to = false;
			//     this.filteredOptions = clients;
			//     return clients;
			//   });

			this.isClientRoleSelected();

			this.userAccessRequestService.getclientSearchData(this.GENERAL_CONFIG.admin_api_url, val ).subscribe( (data: any) => {
				if (data.data.length > 0) {
				console.log("New user access data loaded");
				this.filteredOptions=data.data;
				} else {
				console.log("Error");
				this.AlertService.openSnackBar(
					'No Records Found, Please search with a different keyword.','gtw-snackbar--red'
				);
				}
				}, (error) => {
				console.log(error);
				}, () => {
				console.log('Completed loading');
				});
			}else{
				if(val.length == 0){
					this.selectedClient = {};
				}
			this.isClientRoleSelected();
		}
	}

	addClient(client: any, email_type?: any) {
		this.selectedClient = client;
		this.isClientRoleSelected();
		// const email = {
		// name: user.name,
		// userName: user.userName,
		// emailAddress: user.email,
		// ssoId: user.ssoId,
		// };
		// let completeEmailList = this.userList;
		// if (this.notifactionService.isDulplicateEmail(completeEmailList, email)) {
		//   this.alertService.add('warning', 'Email already added', 1000);
		//   return;
		// } else {
		//   this.userList.push(email);
		//   this.userList = [...this.userList];
		// }
		// this.clientSelected.reset('');
		// this.filteredOptions = [];
	}

	addSelection(){
		console.log(this.user);
		if(!this.selectedClient.CLIENT_NAME){
			this.AlertService.openSnackBar('Client field cannot be empty', 'gtw-snackbar--red');
			return;
		};
		this.clientList.push({
			client_name: this.selectedClient.CLIENT_NAME,
			client_key: this.selectedClient.CLIENT_KEY,
			user_type_key: this.user.user_type
		});
		this.clientSelected.reset('');
		this.user = {};
	}

	isClientRoleSelected() {
		if(!!this.selectedClient.CLIENT_NAME && !!this.user.user_type){
			this.sendRequestBtn = true;
		}else{
			this.sendRequestBtn = false;
		}
		// console.log('Selected User Type ', this.user_type);
		// if (this.user_type.value > 3) {
		// let foundDefaultClient = false;
		// _.each(this.clientList, function (item) {
		// 	if (item.client_key === 3) {
		// 	foundDefaultClient = true;
		// 	console.log( 'in side isDefaultClientAllowed() Found Default Client ::::' );
		// 	}
		// });
		// if (!foundDefaultClient && this.defaultClientData.length > 0) {
		// 	this.clientList.push(this.defaultClientData[0]);
		// }
		// } else {
		// _.forEach(this.clientList, (item, index) => {
		// 	console.log('Item ::', item.client_key);
		// 	if (item.client_key == 3) {
		// 	this.clientList.splice(index, 1);
		// 	console.log( 'in side isDefaultClientAllowed() this.clientList ::::', this.clientList );
		// 	}
		// });
		// }
	}

	changeClient(_client: { updated: boolean; active: number }, event: any) {
		_client.updated = true;
		_client.active = event.target?.checked ? 1 : 0;
	}

	getData() {
		let data: any = {};
		data.user_id = this.user_id;
		this.loading = true;

		this.userAccessRequestService.getNewUserData( this.GENERAL_CONFIG.admin_api_url, this.appService.USER_SETTINGS.user).subscribe( (data: any) => {
			if (data.access_requests.length > 0) {
				//this.pendingRequests = data.access_requests;
				//this.pendingRequests.APPROVER_NAME = this.appService.SERVER_CONFIG.clientConfig.USER_ACCESS_APPROVERS;

				// _.forEach(data.access_requests, (item, index) => {
				// 	item.APPROVER_NAME = this.appService.SERVER_CONFIG.clientConfig.USER_ACCESS_APPROVERS;
				// });
				this.pendingRequests = data.access_requests;
				console.log("New user access data loaded");
			  } else {
				console.log("Error");
			  }
			}, (error) => {
			  console.log(error);
			}, () => {
			  console.log('Completed loading');
		});

	/*	this.UserAdminFactory.getUserDetails(setFormData(data)).subscribe(
		(data: { callSuccess: string; user: any }) => {
			if (data && data.callSuccess === '1') {
			this.user = data.user;

			//this.setUser();
			} else {
			this.AlertService.openSnackBar(
				'User could not be found.',
				MessageType.error
			);
			}
			this.loading = false;
		}
		);
    */
		//this.loadClientsUser();
	}

	requestApproval(){
		console.log(this.user);
		if(!this.selectedClient.CLIENT_NAME){
			this.AlertService.openSnackBar('Client field cannot be empty', 'gtw-snackbar--red');
			return;
		};
    
		let userData : any =[]; 
    
        // _.forEach(access_data, (item, index) => {
		// 	console.log('Item ::', item.client_key);
		// 	userData.push({
		// 		client_key: item.client_key,
		// 		client_name: item.client_name,
		// 		user_email: this.appService.USER_SETTINGS.user.email,
		// 		user_type: item.user_type_key
		// 	})
		// });
		userData.push({
			client_key: this.selectedClient.CLIENT_KEY,
			client_name: this.selectedClient.CLIENT_NAME,
			user_email: this.appService.USER_SETTINGS.user.email,
			user_type: this.user.user_type,
		})

		this.userAccessRequestService.requestUserAccess( this.GENERAL_CONFIG.admin_api_url, this.appService.USER_SETTINGS.user, userData).subscribe( (data: any) => {
			console.log("Request User Access Response ", data);
			if (data.length > 0) {
				console.log(data);
				console.log("New user access data loaded");
				this.AlertService.openSnackBar('Request has been sent for approval.', 'gtw-snackbar--green');
				this.clientSelected.reset('');
				this.user = {};
				this.getData();
			} else {
				console.log("Error");
				this.AlertService.openSnackBar('Error sending the request for approval.', 'gtw-snackbar--red');
			}
		  }, (error) => {
			console.log(error);
		  }, () => {
			console.log('Completed loading');
	  });
	}

	sendReminderMail(access_data: any){
		let userData = {
			client_key: access_data.client_name,
            user_email: this.appService.USER_SETTINGS.user.email,
            user_type: access_data.user_type_key,
			request_key: access_data.REQUEST_KEY
		}
		this.userAccessRequestService.sendReminderMail( this.GENERAL_CONFIG.admin_api_url, this.appService.USER_SETTINGS.user, userData).subscribe( (data: any) => {
			console.log("Send reminder response in component", data);
			if (data.length > 0) {
				console.log("New user access data loaded");
				this.AlertService.openSnackBar('Request has been sent for approval.', 'gtw-snackbar--green');
			} else {
				console.log("Error");
				this.AlertService.openSnackBar('Error sending the request for approval.', 'gtw-snackbar--red');
			}
		  }, (error) => {
			console.log(error);
		  }, () => {
			console.log('Completed loading');
	  });
	}

	//   loadClientsUser() {
	//     this.UserAdminFactory.getUserClients(
	//       setFormData({ user_id: this.rowData.SSO_ID })
	//     ).subscribe((data: { clientResult: any[]; error: string }) => {
	//       if (data.clientResult && data.error === '') {
	//         this.clientList = data.clientResult;
	//         console.log('this.defaultClientData ', this.clientList);
	//         if (this.clientList && this.clientList.length > 0) {
	//           // $rootScope.$broadcast('selectedUserClientsList', this.clientList);
	//           this.gridEventService.broadcast(
	//             'selectedUserclientsList',
	//             this.clientList
	//           );
	//         }
	//         _.each(this.clientList, (item) => {
	//           let filter = _.filter(this.appService.USER_SETTINGS.user.clients, {
	//             client_key: item.client_key,
	//           });
	//           // if((_.filter(USER_SETTINGS.user.clients,{client_key: item.client_key})[0].user_type_key) <= 1){ // here 1 means user_type_key
	//           if (filter && filter[0] && filter[0].user_type_key <= 1) {
	//             //   item.disbaled = true;
	//           } else {
	//             // item.disbaled = false;
	//           }
	//           if (item.client_key == 3) {
	//             this.defaultClientData.push(item);
	//             console.log('this.defaultClientData ::::', this.defaultClientData);
	//           }
	//         });
	//       } else {
	//         this.AlertService.openSnackBar(
	//           'Problem getting clients.',
	//           MessageType.error
	//         );
	//         return;
	//       }
	//     });
	//   }

	// save() {
	// 	let data: any = {};
	// 	data.sso_id = this.user.sso_id;
	// 	data.active = this.active;
	// 	// data.client_active = this.client_active;
	// 	data.user_type = this.user.user_type_key;
	// 	data.role = this.role_type;
	// 	if (this.crudLoading) {
	// 	this.AlertService.openSnackBar(
	// 		'Please wait while we save this request',
	// 		MessageType.warning
	// 	);
	// 	return;
	// 	}

	// 	let clientList: any = [];
	// 	_.each(this.clientList, function (value, key) {
	// 	if (value.updated) {
	// 		let options = {
	// 		client_key: value.client_key,
	// 		active: value.active,
	// 		user_type_key: value.user_type_key,
	// 		};

	// 		clientList.push(options);
	// 	}
	// 	});

	// 	this.crudLoading = true;
	// 	data.clients = JSON.stringify(clientList);

	// 	this.UserAdminFactory.updateUser(setFormData(data)).subscribe(
	// 	(data: { callSuccess: string }) => {
	// 		if (data && data.callSuccess === '1') {
	// 		if (this.user_id != this.appService.USER_SETTINGS.user.sso_id) {
	// 			this.getData();
	// 		}
	// 		this.crudLoading = false;
	// 		this.AlertService.openSnackBar(
	// 			'User has been updated',
	// 			MessageType.success
	// 		);
	// 		this.gridEventService.broadcast('gridUpdate', {
	// 			sso_id: this.user_id,
	// 		});
	// 		} else {
	// 		this.getData();
	// 		}
	// 		this.crudLoading = false;
	// 	}
	// 	);
	// }

	cancel() {
		this.emitEvent.emit('cancel');
	}
	}
