import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { AppService } from 'projects/gtw-elements/src/app/app.service';
//import { AdminFactoryService } from 'projects/gtw-elements/src/app/global/services/admin-factory.service';
//import { GlobalService } from 'projects/gtw-elements/src/app/global/services/global.service';
import { GtwSnackbarService } from 'projects/gtw-elements/src/app/shared/_services/gtw-snackbar.service';
//import { NotifactionService } from '../../notification/notifaction.service';
//import { TeamplateAssignService } from '../../template-assign/teamplate-assign.service';
import { UserAccessApprovalService } from '../user-access-approval.service';
import { environment } from 'projects/gtw-elements/src/environments/environment';

@Component({
	selector: 'gtw-reject-user-request',
	templateUrl: './reject-user-request.component.html',
	styleUrls: ['./reject-user-request.component.scss']
})
export class RejectUserRequestComponent implements OnInit {
	user: any;
	clients: any;
	selectedAppClient: any;
	userList: any;
	templateGroups: any;
	allTemplates: any;
	templatesGroup: any;
	userAssignNav: any;
	searchTerm: any;
	selectedApplication: any;
	minimise: any;
	title: any;
	taskModel: any;
	searchText: any;
	selectedTemplate: any;
	rowData : any;
	rejectMessage : any;
	GENERAL_CONFIG: any;
	loading: any;
	
	constructor(
		//private appService: AppService,
		//private adminFactory: AdminFactoryService,
		//private globalService: GlobalService,
		private AlertService: GtwSnackbarService,
		//private templateAssignFactory: TeamplateAssignService,
		//private notifactionService: NotifactionService,
		public diagRef: MatDialogRef<any>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		private userAccessApprovalService: UserAccessApprovalService
	) { }

	ngOnInit(): void {
		this.title = "Reject Request";
		this.GENERAL_CONFIG = environment;
	}

	rejectRequest(){
		let userData : any = {
			requester_email: this.data.USER_EMAIL,
			request_key: this.data.REQUEST_KEY,
			msg: this.rejectMessage,
            client_key: this.data.CLIENT_KEY
		} 

		this.userAccessApprovalService.rejectUserAccess( 
			this.GENERAL_CONFIG.admin_api_url, 
			this.data.globalService.userSettingsData.user, 
			this.data.globalService,
			userData).subscribe( (data: any) => {
			if (data.length > 0) {
				this.AlertService.openSnackBar('Request has been rejected successfully.', 'gtw-snackbar--green');
			} else {
				this.AlertService.openSnackBar('Error in rejecting request.', 'gtw-snackbar--red');
			}
		  }, (error) => {
			console.log(error);
		  }, () => {
			console.log('Completed loading');
	  	});
		this.cancel();
	}
	
	cancel(){
		this.diagRef.close();
	};

}
