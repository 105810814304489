import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { createCustomElement } from '@angular/elements';

import { AppNgMaterialModule } from './app-ng-material.module';
import { ScreenVideoModule } from './screen-video-container/screen-video.module';
import { ScreenVideoContainerComponent } from './screen-video-container/screen-video-container.component';
import { TrialBalanceInventoryModule } from './trial-balance-inventory/trial-balance-inventory.module';
import { EntityChartUpdateComponent } from './trial-balance-inventory/entity-chart-update/entity-chart-update.component';
import { HomePageModule } from './home-page/home-page.module';
import { HomePageContainerComponent } from './home-page/home-page-container.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationModule } from './navigation/navigation.module';
import { HeaderComponent } from './header/header.component';
import { HeaderModule } from './header/header.module';
import { ScreensComponent } from './screens/screens.component';
import { ScreensModule } from './screens/screens.module';
import { GhostConsoleComponent } from './ghost-console/ghost-console.component';
import { GhostConsoleModule } from './ghost-console/ghost-console.module';
import { EfileModule } from './efile/efile.module';
import { SubmissionDataComponent } from './efile/submission-data/submission-data.component';
import { UploadIRSComponent } from './efile/upload-irs-schema/upload-irs.component';
import { CreateDisclosureComponent } from './DST/statement-tracker/create-disclosure/create-new/create-disclosure.component';
import { DSTModule } from './DST/dst.module';
import { StatementTrackerComponent } from './DST/statement-tracker/statement-tracker.component';
import { AdminModule } from './admin/admin.module';

import { CustomReportComponent } from './DST/reports/custom-report/custom-report.component'; import { ScreenDocsModule } from './screen-docs/screen-docs.module';
import { ScreenDocumentsComponent } from './screen-docs/screen-documents.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { NaReportComponent } from './DST/diagnostics/na-report/na-report.component';
import { DataCollectReportComponent } from './DST/diagnostics/data-collect-report/data-collect-report.component';
import { Sec351ReportComponent } from './DST/reports/sec-351-report/sec-351-report.component';
import { Sec332ReportComponent } from './DST/reports/sec-332-report/sec-332-report.component';
import { Sec368ReportComponent } from './DST/reports/sec-368-report/sec-368-report.component';
import { Sec926ReportComponent } from './DST/reports/sec-926-report/sec-926-report.component';
import { Form8594ReportComponent } from './DST/reports/form-8594-report/form-8594-report.component';
import { Form8883ReportComponent } from './DST/reports/form-8883-report/form-8883-report.component';
import { MefEfileChangesComponent } from './admin/components/mef-efile-changes/mef-efile-changes.component';
import { PartnershipModule } from './partnership/partnership.module';
import { F8865SchM2K1SourcingComponent } from './partnership/components/f8865-sch-m2-k1-sourcing/f8865-sch-m2-k1-sourcing.component';
import { SupplementalInfoComponent } from './foreign/components/supplemental-info/supplemental-info.component';
import { ForeignModule } from './foreign/foreign.module';
import { FtcModule } from './ftc/ftc.module';
import { NaicsMaintenanceComponent } from './admin/components/naics-maintenance/naics-maintenance.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { EntityChartMapCleanUpComponent } from './admin/components/entity-chart-map-clean-up/entity-chart-map-clean-up.component';
import { DetailDiagnosticComponent } from './diagnostic-dashboard/detail-diagnostic/detail-diagnostic.component';
import { SummaryDiagnosticComponent } from './diagnostic-dashboard/summary-diagnostic/summary-diagnostic.component';

import { SplAllocComponent } from './partnership/components/spl-alloc/spl-alloc.component';
import { TransfersReviewReportComponent } from './DST/diagnostics/transfers-review-report/transfers-review-report.component';
import { SourcingDefaultMeComponent } from './foreign/components/sourcing-default-me/sourcing-default-me.component';
import { SourcingAmountsComponent } from './partnership/components/sourcing-amounts/sourcing-amounts.component';
import { SpotratesAllCurrSyncComponent } from './foreign/components/spotrates-all-curr-sync/spotrates-all-curr-sync.component';
import { FxAmtSyncComponent } from './foreign/components/fx-amt-sync/fx-amt-sync.component';
import { BatchSourcingDefaultComponent } from './foreign/components/batch-sourcing-default/batch-sourcing-default.component';
import { DashboardFilterComponent } from './diagnostic-dashboard/dashboard-filter/dashboard.filter.component';
import { CountByBusinessComponent } from './DST/reports/count-by-business/count-by-business.component';
import { DupsStmtReportComponent } from './DST/diagnostics/dups-stmt-report/dups-stmt-report.component';
import { F304DiagReportComponent } from './DST/diagnostics/f304-diag-report/f304-diag-report.component';
import { SourcingDeleteRecordsComponent } from './foreign/components/sourcing-delete-records/sourcing-delete-records.component';
import { DiagnosticsReportComponent } from './DST/reports/diagnostics-report/diagnostics-report.component';
import { TestedUnitsSyncComponent } from './foreign/components/tested-units-sync/tested-units-sync.component';
import { F1118SchdCComponent } from './ftc/components/f1118-schd-c/f1118-schd-c.component';
import { FxRatesSyncComponent } from './foreign/components/fx-rates-sync/fx-rates-sync.component';
import { SourcingProjectTaggingComponent } from './foreign/components/sourcing-project-tagging/sourcing-project-tagging.component';
import { DisregardedEntitiesComponent } from './DST/reports/disregarded-entities/disregarded-entities.component';
import { S3FileUpload } from './admin/components/s3-file-upload/s3-file-upload.component';
import { DomesticProjectTaggingComponent } from './foreign/components/domestic-project-tagging/domestic-project-tagging.component';
import { PartnershipDashboardComponent } from './partnership/components/partnership-dashboard/partnership-dashboard.component';
import { GhostUpdateCombinationComponent } from './foreign/components/ghost-update-combination/ghost-update-combination.component';
import { DfMappingChangesComponent } from './admin/components/df-mapping-changes/df-mapping-changes.component';
import { Form8082Component } from './domestic/components/form8082/form8082.component';
import { FgnF5471H1Component } from './foreign/components/fgn-f5471-h1/fgn-f5471-h1.component';
import { BepsCategoriesComponent } from './cbc/admin/beps-categories/beps-categories.component';
import { CBCModule } from './cbc/cbc.module';
import { BepsQuartersComponent } from './cbc/admin/beps-quarters/beps-quarters.component';
import { DfAssignEntityComponent } from './partnership/components/df-assign-entity/df-assign-entity.component';
import { FgnOverrides163jComponent } from './foreign/components/fgn-overrides163j/fgn-overrides163j.component';
import { BepsCountriesComponent } from './cbc/admin/beps-countries/beps-countries.component';
import { SettlementRedirectionComponent } from './foreign/components/settlement-redirection/settlement-redirection.component';
import { EfileReviewAttachmentUpload } from './efile/review-attachment-upload/review-attachment-upload.component';
import { PostingPartnersComponent } from './admin/components/posting-partners/posting-partners.component';
import { DataPushToEfileComponent } from './DST/admin/data-push-to-efile/data-push-to-efile.component';
import { PdfPushToEfileComponent } from './DST/admin/pdf-push-to-efile/pdf-push-to-efile.component';
import { UserAccessRequestComponent } from './admin/components/user-access-request/user-access-request.component';
import { UserAccessApprovalComponent } from './admin/components/user-access-approval/user-access-approval.component';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppNgMaterialModule,
    ScreenVideoModule,
    TrialBalanceInventoryModule,
    HomePageModule,
    NavigationModule,
    HeaderModule,
    ScreensModule,
    HomePageModule,
    EfileModule,
    DSTModule,
    GhostConsoleModule,
    ScreenDocsModule,
    AdminModule,
    PartnershipModule,
    ForeignModule,
    FtcModule,
    CBCModule
  ],
  providers: [],
  entryComponents: [
    ScreenVideoContainerComponent,
    EntityChartUpdateComponent,
    HomePageContainerComponent,
    NavigationComponent,
    HeaderComponent,
    ScreensComponent,
    GhostConsoleComponent,
    ScreenDocumentsComponent,
    SupplementalInfoComponent,
    F1118SchdCComponent
  ],
})
export class AppElementsModule {
  constructor(private injector: Injector) {
  }

  ngDoBootstrap() {

    // Register Screen Video Component
    console.log('Registering ScreenVideo Element');
    const ScreenVideoElement = createCustomElement(ScreenVideoContainerComponent, { injector: this.injector });
    customElements.define('gtw-screen-video-container-ce', ScreenVideoElement);

    // Register Entity Chart Component
    console.log('Registering Entity Chart Update Element');
    const EntityChartUpdate = createCustomElement(EntityChartUpdateComponent, { injector: this.injector });
    customElements.define('gtw-entity-chart-update-ce', EntityChartUpdate);

    console.log('Registering Home Page Element');
    const HomePageElement = createCustomElement(HomePageContainerComponent, { injector: this.injector });
    customElements.define('gtw-home-page-ce', HomePageElement);

    //Header Element
    console.log('Header Element');
    const HeaderElement = createCustomElement(HeaderComponent, { injector: this.injector });
    customElements.define('gtw-header-ce', HeaderElement);

    //Footer Element
    console.log('Footer Element');
    const FooterElement = createCustomElement(AppFooterComponent, { injector: this.injector });
    customElements.define('gtw-footer-ce', FooterElement);

    //Screens Element
    console.log('Screens Element');
    const ScreenElement = createCustomElement(ScreensComponent, { injector: this.injector });
    customElements.define('gtw-screen-ce', ScreenElement);

    //Left Navigation Element
    console.log('Navigation Element');
    const NavigationElement = createCustomElement(NavigationComponent, { injector: this.injector });
    customElements.define('gtw-navigation-ce', NavigationElement);

    //Ghost Console Element
    console.log('Ghost Console Element');
    const GhostConsoleElement = createCustomElement(GhostConsoleComponent, { injector: this.injector });
    customElements.define('gtw-ghost-console-ce', GhostConsoleElement);

    // console.log('Registering Efile Submission Element');
    // const EfileSubmissionElement = createCustomElement(SubmissionDataComponent, {injector: this.injector});
    // customElements.define('gtw-efile-submission', EfileSubmissionElement);

    // console.log('Registering Efile Submission Element');
    // const LoadIRSSChemaElement = createCustomElement(UploadIRSComponent, {injector: this.injector});
    // customElements.define('gtw-efile-upload-irs-schema', LoadIRSSChemaElement);

    // console.log('Registering DST Create Disclosure Element');
    // const CreateDisclosureElement = createCustomElement(CreateDisclosureComponent, {injector: this.injector});
    // customElements.define('gtw-create-disclosure', CreateDisclosureElement);

    console.log('Registering DST Statement Tracker Element');
    const StatementTrackerElement = createCustomElement(StatementTrackerComponent, { injector: this.injector });
    customElements.define('gtw-statement-tracker', StatementTrackerElement);

    console.log('Registering DST Custom Report Element');
    const CustomReportElement = createCustomElement(CustomReportComponent, { injector: this.injector });
    customElements.define('dst-custom-report', CustomReportElement);

    console.log('Registering DST Reports- Section 351 Report Element');
    const Section351ReportElement = createCustomElement(Sec351ReportComponent, { injector: this.injector });
    customElements.define('gtw-sec351-report', Section351ReportElement);

    console.log('Registering DST Reports- Section 332 Report Element');
    const Section332ReportElement = createCustomElement(Sec332ReportComponent, { injector: this.injector });
    customElements.define('gtw-sec332-report', Section332ReportElement);

    console.log('Registering DST Reports- Section 368 Report Element');
    const Section368ReportElement = createCustomElement(Sec368ReportComponent, { injector: this.injector });
    customElements.define('gtw-sec368-report', Section368ReportElement);

    console.log('Registering DST Reports- Section 926 Report Element');
    const Section926ReportElement = createCustomElement(Sec926ReportComponent, { injector: this.injector });
    customElements.define('gtw-sec926-report', Section926ReportElement);

    console.log('Registering DST Reports- Form 8594 Report Element');
    const Form8594ReportElement = createCustomElement(Form8594ReportComponent, { injector: this.injector });
    customElements.define('gtw-form8594-report', Form8594ReportElement);

    console.log('Registering DST Reports- Form 8883 Report Element');
    const Form8883ReportElement = createCustomElement(Form8883ReportComponent, { injector: this.injector });
    customElements.define('gtw-form8883-report', Form8883ReportElement);

    console.log('Registering DST Diagnostics N/A Report Element');
    const NAReportElement = createCustomElement(NaReportComponent, { injector: this.injector });
    customElements.define('gtw-na-report', NAReportElement);

    console.log('Registering DST Diagnostics Data Collect Report Element');
    const DataCollectReportElement = createCustomElement(DataCollectReportComponent, { injector: this.injector });
    customElements.define('gtw-data-collect-report', DataCollectReportElement);

    console.log('Registering DST Diagnostics Transfers Review Report Element');
    const TransfersReviewReportElement = createCustomElement(TransfersReviewReportComponent, { injector: this.injector });
    customElements.define('gtw-transfers-review-report', TransfersReviewReportElement);

    console.log('Registering DST Duplicate Statements Report Element');
    const DupsStatementReportElement = createCustomElement(DupsStmtReportComponent, { injector: this.injector });
    customElements.define('gtw-dups-stmt-report', DupsStatementReportElement);

    console.log('Registering DST Duplicate Statements Report Element');
    const F304DiagReportElement = createCustomElement(F304DiagReportComponent, { injector: this.injector });
    customElements.define('gtw-f304-diag-report', F304DiagReportElement);

    console.log('Registering Screen Documents');
    const ScreenDocsTrackerElement = createCustomElement(ScreenDocumentsComponent, { injector: this.injector });
    customElements.define('gtw-screen-documents-ce', ScreenDocsTrackerElement);

    const ReleaseNotesElement = createCustomElement(ReleaseNotesComponent, { injector: this.injector });
    customElements.define('gtw-release-notes-ce', ReleaseNotesElement);

    console.log('Registering Mef/Efile Changes');
    const MefEfileChangesElement = createCustomElement(MefEfileChangesComponent, { injector: this.injector });
    customElements.define('gtw-mef-efile-changes', MefEfileChangesElement);

    console.log('Registering Form 8082 Changes');
    const Form8082Element = createCustomElement(Form8082Component, { injector: this.injector });
    customElements.define('gtw-form-8082', Form8082Element);

    console.log('Registering partnership F8865 Sch M2K1 Sourcing Element');
    const F8865SchM2K1SourcingElement = createCustomElement(F8865SchM2K1SourcingComponent, { injector: this.injector });
    customElements.define('gtw-f8865-sch-m2-k1-sourcing', F8865SchM2K1SourcingElement);

    console.log('Supplemental Info');
    const SupplementalInfoElement = createCustomElement(SupplementalInfoComponent, { injector: this.injector });
    customElements.define('gtw-supplemental-info-ce', SupplementalInfoElement);

    console.log('Registering NAICS Code Maintenance Changes');
    const naicsMaintenanceChangesElement = createCustomElement(NaicsMaintenanceComponent, { injector: this.injector });
    customElements.define('gtw-naics-maintenance', naicsMaintenanceChangesElement);

    console.log('Registering Entity CleanUp UI Changes');
    const entityCleanUpElement = createCustomElement(EntityChartMapCleanUpComponent, { injector: this.injector });
    customElements.define('gtw-entity-chart-map-clean-up', entityCleanUpElement);

    console.log('Detail Diagnostic Element');
    const detailDiagnosticElement = createCustomElement(DetailDiagnosticComponent, { injector: this.injector });
    customElements.define('gtw-detail-diagnostic-ce', detailDiagnosticElement);

    console.log('Summary Diagnostic Element');
    const summaryDiagnosticElement = createCustomElement(SummaryDiagnosticComponent, { injector: this.injector });
    customElements.define('gtw-summary-diagnostic-ce', summaryDiagnosticElement);

    console.log('Registering Partnership Special Allocation Element');
    const SplAllocElement = createCustomElement(SplAllocComponent, { injector: this.injector });
    customElements.define('gtw-pship-spl-alloc', SplAllocElement);

    console.log('Sourcing Default Me');
    const SourcingDefaultMeElement = createCustomElement(SourcingDefaultMeComponent, { injector: this.injector });
    customElements.define('gtw-sourcing-default-me-ce', SourcingDefaultMeElement);

    console.log('Registering Partnership Sourcing Amount Element');
    const SourcingAmtsElement = createCustomElement(SourcingAmountsComponent, { injector: this.injector });
    customElements.define('gtw-sourcing-amounts', SourcingAmtsElement);

    console.log('Sourcing Default Me');
    const SpotratesAllCurrSyncElement = createCustomElement(SpotratesAllCurrSyncComponent, { injector: this.injector });
    customElements.define('gtw-spotrates-all-curr-sync-ce', SpotratesAllCurrSyncElement);

    console.log('Fx Amount Sync');
    const FxAmtSyncElement = createCustomElement(FxAmtSyncComponent, { injector: this.injector });
    customElements.define('gtw-fx_amt-sync-ce', FxAmtSyncElement);

    console.log('Batch Sourcing Default Me');
    const BatchSourcingDefaultMeElement = createCustomElement(BatchSourcingDefaultComponent, { injector: this.injector });
    customElements.define('gtw-batch-sourcing-default-me-ce', BatchSourcingDefaultMeElement);

    console.log('Diagnostic Common Filter');
    const DashboardFilterElement = createCustomElement(DashboardFilterComponent, { injector: this.injector });
    customElements.define('gtw-dashboard-filter-ce', DashboardFilterElement);

    console.log('Count By Business');
    const CountByBusinessElement = createCustomElement(CountByBusinessComponent, { injector: this.injector });
    customElements.define('gtw-count-by-business', CountByBusinessElement);

    console.log('Delete Sourcing Records');
    const DeleteSourcingRecordElement = createCustomElement(SourcingDeleteRecordsComponent, { injector: this.injector });
    customElements.define('gtw-sourcing-delete-records-ce', DeleteSourcingRecordElement);

    console.log('Diagnostics Report');
    const DiagnosticsReportElement = createCustomElement(DiagnosticsReportComponent, { injector: this.injector });
    customElements.define('gtw-diagnostics-report', DiagnosticsReportElement);

    console.log('Tested Units Sync');
    const TestedUnitsSyncElement = createCustomElement(TestedUnitsSyncComponent, { injector: this.injector });
    customElements.define('gtw-fir-tested-units-sync-ce', TestedUnitsSyncElement);

    console.log('Setttlement Redirection');
    const GhostUpdateCombinationElement = createCustomElement(GhostUpdateCombinationComponent, { injector: this.injector });
    customElements.define('gtw-ghost-update-combination-ce', GhostUpdateCombinationElement);

    console.log('F1118SchdCComponent');
    const F1118SchdCElement = createCustomElement(F1118SchdCComponent, { injector: this.injector });
    customElements.define('gtw-f1118-schd-c-ce', F1118SchdCElement);

    console.log('Fx Rates Sync');
    const FxRatesSyncElement = createCustomElement(FxRatesSyncComponent, { injector: this.injector });
    customElements.define('gtw-fx_rates-sync-ce', FxRatesSyncElement);

    console.log('Sourcing Project Tagging');
    const SourcingProjectTaggingElement = createCustomElement(SourcingProjectTaggingComponent, { injector: this.injector });
    customElements.define('gtw-sourcing-project-tagging-ce', SourcingProjectTaggingElement)

    console.log('Disregarded Entities Report');
    const DisregardedEntitiesElement = createCustomElement(DisregardedEntitiesComponent, { injector: this.injector });
    customElements.define('gtw-disregarded-entities', DisregardedEntitiesElement);


    console.log('s3 file upload component');
    const S3FileUploadElement = createCustomElement(S3FileUpload, { injector: this.injector });
    customElements.define('gtw-s3-file-upload-ce', S3FileUploadElement);

    console.log('Domestic Project Tagging');
    const DomesticProjectTaggingElement = createCustomElement(DomesticProjectTaggingComponent, { injector: this.injector });
    customElements.define('gtw-domestic-project-tagging-ce', DomesticProjectTaggingElement)

  console.log('Partnership Dashboard');
    const PartnershipDashboardElement = createCustomElement(PartnershipDashboardComponent, {injector: this.injector});
    customElements.define('gtw-partnership-dashboard-ce', PartnershipDashboardElement);

  console.log('DF Mapping Changes');
    const DfMappingChangesElement = createCustomElement(DfMappingChangesComponent, {injector: this.injector});
    customElements.define('gtw-df-mapping-changes-ce', DfMappingChangesElement);

    console.log('Fgn F5471 H1');
    const Fgnf5471h1Elment = createCustomElement(FgnF5471H1Component, { injector: this.injector });
    customElements.define('gtw-fir-f5471-h1-ce', Fgnf5471h1Elment);

  console.log('bepsCategories Dashboard');
    const BepsCategoriesElement = createCustomElement(BepsCategoriesComponent, {injector: this.injector});
    customElements.define('gtw-cbc-beps-categories-ce', BepsCategoriesElement);



  console.log('beps quarters Dashboard');
    const BepsQuarterseElement = createCustomElement(BepsQuartersComponent, {injector: this.injector});
    customElements.define('gtw-cbc-beps-quarters-ce', BepsQuarterseElement);

console.log('DF Assign Entity');
    const DfAssignEntityElement = createCustomElement(DfAssignEntityComponent, {injector: this.injector});
    customElements.define('gtw-df-assign-entity-ce', DfAssignEntityElement);

    console.log('Fgn 163j overrides');
    const FgnOverrides163jElment = createCustomElement(FgnOverrides163jComponent, { injector: this.injector });
    customElements.define('gtw-fir-overrides163j-ce', FgnOverrides163jElment);

    console.log('Ghost Setttlement Redirection');
    const SettlementRedirectionElement = createCustomElement(SettlementRedirectionComponent, { injector: this.injector });
    customElements.define('gtw-settlement-redirection-ce', SettlementRedirectionElement);


    console.log('Beps Countries');
    const BepsCountriesElement = createCustomElement(BepsCountriesComponent, { injector: this.injector });
    customElements.define('gtw-cbc-beps-countries-ce', BepsCountriesElement);

    console.log('Efile Review Attachment Upload');
    const EfileReviewAttachmentsUploadElement = createCustomElement(EfileReviewAttachmentUpload, { injector: this.injector });
    customElements.define('gtw-efile-review-attachments-ce', EfileReviewAttachmentsUploadElement);

    console.log('Posting Partners');
    const PostingPartnersComponentElement = createCustomElement(PostingPartnersComponent, { injector: this.injector });
    customElements.define('gtw-posting-partners-ce', PostingPartnersComponentElement);

    console.log('DST Data Push to Efile');
    const DstDataPushToEfileElement = createCustomElement(DataPushToEfileComponent, { injector: this.injector });
    customElements.define('gtw-dst-efile-datapush-ce', DstDataPushToEfileElement);

    console.log('DST pdf Push to Efile');
    const DstPdfPushToEfileElement = createCustomElement(PdfPushToEfileComponent, { injector: this.injector });
    customElements.define('gtw-dst-efile-pdf-push-ce', DstPdfPushToEfileElement);

    //User Access Request Page
    console.log('User Access Request Page');
    const UserAccessRequestElement = createCustomElement(UserAccessRequestComponent, { injector: this.injector });
    customElements.define('gtw-user-access-request-ce', UserAccessRequestElement);

    //User Access Approval Page
    console.log('User Access Approval Page');
    const UserAccessApprovalElement = createCustomElement(UserAccessApprovalComponent, { injector: this.injector });
    customElements.define('gtw-user-access-approval-ce', UserAccessApprovalElement);
  }




}
